{
    "global_olympic_content_01": "The 2024 Paris Olympics draws worldwide attention. As a fashion hub,  Paris will host numerous talented designers and fashion enthusiasts.",
    "global_olympic_content_02": "Therefore, we are organizing an international design competition titled  \"Recreate Olympic Fashion with Style3D\" to infuse the Games with a  fashionable ambiance. Style3D sparks  creativity, offering a platform for  talented individuals to showcase their skills.",
    "global_olympic_content_03": "What are we looking for?",
    "global_olympic_content_04": "Participants",
    "global_olympic_content_05": "<span class=\"large\">Designers</span> (independent designers/design studios)",
    "global_olympic_content_06": "<span class=\"large\">Students from fashion/design institutions</span>",
    "global_olympic_content_07": "Requirements",
    "global_olympic_content_08": "Create a unique outfit design for Olympic athletes or spectators.",
    "global_olympic_content_09": "Designs must be related to the Olympic theme or  Parisian elements",
    "global_olympic_content_10": "All entries must be created using the Style3D products including Style3D Studio, Style3D iWish, etc.",
    "global_olympic_content_11": "Concepts to explore",
    "global_olympic_content_12": "Designs should reflect the spirit and values of the Olympics, including but not limited to global peace, mutual understanding, lasting friendship, global unity, fair competition, self-improvement, sustainable development, celebration of sports, cultural diversity, the Olympic city experience, etc.",
    "global_olympic_content_13": "How to apply?",
    "global_olympic_content_14": "Competition entries must be submitted before <span class=\"large\">August 15, 2024 </span> deadline.  You will be asked to submit a short proposal document alongside additional information through an application form.",
    "global_olympic_content_15": "Your submissions should include:",
    "global_olympic_content_16": "Garment design sketches (optional)",
    "global_olympic_content_17": "Finished photos or videos",
    "global_olympic_content_18": "3D project files ",
    "global_olympic_content_19": "Brief description (300 words or less) of the design concept",
    "global_olympic_content_20": "If you have any questions, please feel free to contact us by email at <a href=\"mailto:marketing@Style3D.com\" style=\"text-decoration:underline\">marketing@Style3D.com</a>.",
    "global_olympic_content_21": "More Details",
    "global_olympic_content_22": "Timeline",
    "global_olympic_content_23": "Submission Period",
    "global_olympic_content_24": "Judging and Public Voting",
    "global_olympic_content_25": "Winners Announced",
    "global_olympic_content_26": "May 25 - July 25",
    "global_olympic_content_27": "July 25 - August 10",
    "global_olympic_content_28": "August 15",
    "global_olympic_content_29": "Award Categories",
    "global_olympic_content_30": "Best  Design Award<span class=\"large\">(1 winner)</span>",
    "global_olympic_content_31": "＄2000 Bonus",
    "global_olympic_content_32": "A Tour of Style3D Headquarters / An Internship Opportunity at Renowned Brands",
    "global_olympic_content_33": "An Exclusive Interview for Increased Exposure",
    "global_olympic_content_33_01": "An Official Certificate of Recognition from Style3D",
    "global_olympic_content_33_02": "Free Style3D Studio Account for 1 Year",
    "global_olympic_content_34": "Most Creative Design<span class=\"large\">(2 winner)</span>",
    "global_olympic_content_35": "＄1000 Bonus",
    "global_olympic_content_36": "Day Tour / Training in Styleverse",
    "global_olympic_content_37": "An Exclusive Interview for Increased Exposure",
    "global_olympic_content_37_01": "An Official Certificate of Recognition from Style3D",
    "global_olympic_content_37_02": "Free Style3D Studio Account for 1 Year",
    "global_olympic_content_38": "Popular Choice Award<span class=\"large\">(up to 3 winners)</span>",
    "global_olympic_content_39": "Day Tour / Training in Styleverse",
    "global_olympic_content_40": "An Official Certificate of Recognition from Style3D",
    "global_olympic_content_41": "Free Style3D Studio Account for 1 Year",
    "global_olympic_content_42": "Honorable Mentions <span class=\"large\">(up to 5 winners)</span>",
    "global_olympic_content_43": "An Official Certificate of Recognition from Style3D",
    "global_olympic_content_43_01": "Free Style3D Studio Account for 1 Year",
    "global_olympic_content_44": "All outstanding works have the opportunity to collaborate with Style3D, transforming 3D creations into unique pieces or collections, and showcasing them as exhibits in Styleverse London/Paris/Munich.",
    "global_olympic_content_45": "Other Terms",
    "global_olympic_content_46": "Competition entries must be original works. Identical or similar works that have been previously published will not  be considered. Competition entries must not infringe upon others' intellectual property rights; participants shall bear responsibility for any disputes arising therefrom.",
    "global_olympic_content_47": "Participants retain the intellectual property rights to their submitted works. By submitting an entry, participants  authorize Style3D to promote, display, publish, and otherwise publicize the submitted works in various forms.",
    "global_olympic_content_48": "Style3D reserves the final interpretation rights for this competition.",
    "global_olympic_content_49": "Click here to Submit",
    "global_olympic_content_50": "Create and Submit: May 25 - August 15",
    "global_olympic_content_51": "Judging and Voting: August 16 - August 29",
    "global_olympic_content_52": "Winners Announced: August 30",
    "global_olympic_email_en": "If you have any questions, please feel free to contact us by email at <a style=\"font-size: 18px;font-family: Montserrat;color: #e7e7e7;\" href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>.",
    "global_olympic_email_cn": "If you have any questions, please feel free to contact us by email at <a style=\"font-size: 20px;font-family: HarmonyOS Sans SC;color: #e7e7e7;\" href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>.",
    "global_olympic_email_mobile_en": "If you have any questions, please feel free to contact us by email at <a style=\"font-size: 10px;font-family: Montserrat;color: #e7e7e7;\" href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>.",
    "global_olympic_email_mobile_cn": "If you have any questions, please feel free to contact us by email at <a style=\"font-size: 12px;font-family: HarmonyOS Sans SC;color: #e7e7e7;\" href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>.",
    "global_olympic_text7a_en": "Other Terms",
    "global_olympic_text7b1_en": "Competition entries must be original works. Identical or similar works that have been previously published will not be considered. Competition entries must not infringe upon others' intellectual property rights; participants shall bear  responsibility  for any disputes arising therefrom.",
    "global_olympic_text7b2_en": "Participants retain the intellectual property rights to their submitted works.  By submitting an entry, participants authorize Style3D to promote, display,  publish, and otherwise  publicize the submitted works in various forms.",
    "global_olympic_text7b3_en": "This campaign is not affiliated with the Olympic Games or its sponsors. Style3D reserves the final interpretation rights for this competition.",
    "global_olympic_title6_en": "🎨 INS Sharing Benefits",
    "global_olympic_text6_en": "Post your competition entries on Instagram, include the hashtag <span style=\"color: #52ff8d;\">#RecreateOlympicFashion</span>, and <span style=\"color: #52ff8d;\">@style3d_official</span>. We will select outstanding creators to receive a special gift.",
    "global_olympic_text6_mobile": "<span style=\"opacity: 0.75;\">Post your competition entries on Instagram, <br/>include the hashtag </span><span style=\"color: #52ff8d;opacity: 1;\">#RecreateOlympicFashion</span><span style=\"opacity: 0.75;\">, and </span><span style=\"color: #52ff8d;opacity: 1;\">@style3d_official</span><span style=\"opacity: 0.75;\">. We will select outstanding creators to receive a special gift.</span>",
    "global_olympic_banner4_text1_en": "How to apply?",
    "global_olympic_banner4_text2_en": "Competition entries must be submitted before August 15, deadline.",
    "global_olympic_banner4_text2a_en": "You will be asked to submit a short proposal document alongside additional information through an application form.",
    "global_olympic_banner4_text3_en": "Your submissions should include:",
    "global_olympic_banner4_text4a_en": "Garment design sketches (optional)",
    "global_olympic_banner4_text4b_en": "Finished photos or videos",
    "global_olympic_banner4_text4c_en": "3D project files",
    "global_olympic_banner4_text4d_en": "Brief description (300 words or less) of the design concept",
    "global_olympic_banner1_text1_en": "The 2024 Paris Olympics draws worldwide attention. As a fashion hub, Paris will host numerous talented designers and fashion enthusiasts.",
    "global_olympic_banner1_text2_en": "Therefore, we are organizing an international design competition titled &quot;Recreate Olympic Fashion with Style3D&quot; to infuse the Games with a fashionable ambiance. Style3D sparks creativity, offering a platform for talented individuals to showcase their skills.",
    "global_olympic2_titl1": "Recreate Olympic Fashion with Style3D",
    "global_olympic2_titl2": "",
    "global_olympic_header_text1_en": "Recreate Olympic Fashion",
    "global_olympic_header_text2_en": "With Style3D",
    "global_company_synopsis": "About Us",
    "global_company_vision": "Our Vision",
    "global_company_values": "Our Values",
    "global_thanks_title_1": "Thank you for your interest!",
    "global_thanks_title_2": "We will be in touch with you shortly.",
    "global_thanks_callback": "Back to Home",
    "global_hello": "Hello",
    "global_oops_page_not_found": "Oops! Page not found.",
    "global_back_to_home": "Back to homepage",
    "global_internal_error": "Internal error",
    "global_reload": "Reload",
    "global_link_button_0": "Learn more",
    "global_link_button_1": "Contact Style3D",
    "global_link_button_2": "Get verification code",
    "global_link_button_3": "Contact us",
    "global_link_button_4": "Download application form",
    "global_link_button_5": "More News",
    "global_link_button_6": "Download Software",
    "global_link_button_7": "Submit",
    "global_link_button_8": "Start Here",
    "global_link_Individual": "Individual User",
    "global_link_Corporate": "Corporate User",
    "global_link_button_9": "register now",
    "global_link_button_10": "Click here to Submit",
    "global_button_text_accept": "Accept",
    "global_button_text_decline": "Decline",
    "global_validate_message_0": "Please enter",
    "global_validate_message_1": " Please enter your phone number!",
    "global_validate_message_2": "Please enter a valid phone number!",
    "global_validate_message_3": "Please enter your email address",
    "global_validate_message_4": "Please enter a valid email address!",
    "global_apply_trial_submit_success": "Submitted successfully",
    "global_apply_trial_submit_failed": "Submission failed",
    "global_home_swipper_text": "What you see is what you get",
    "global_home_swipper_text_mobile": "What you see is what you get",
    "global_home_swipper_subtext": "Combining state-of-the-art AI and simulation technology, Style3D offers digital fashion software, platforms, solutions, and services for both individuals and enterprises.",
    "global_home_swipper_subtext_mobile": "Combining state-of-the-art AI and simulation technology, Style3D offers digital fashion software, platforms, solutions, and services for both individuals and enterprises.",
    "global_home_swipper_olympic_title": "Recreate Olympic Fashion with Style3D",
    "global_home_swipper_olympic_text": "Join Our Global Campaign, <br>Unleash Your Creativity and Win Big!",
    "global_home_swipper_olympic_date": "25/5-15/8",
    "global_home_swipper_secbanner_1": "CHIC&intertextile开展在即，Style3D展位精彩亮点抢先看！",
    "global_home_swipper_banner": "Learn More",
    "global_home_header_menu_0": "Products",
    "global_home_catalogue_category_0": "Style3D",
    "global_home_catalogue_item_name_0": "Studio",
    "global_home_catalogue_item_name_1": "Fabric",
    "global_home_catalogue_item_name_2": "Cloud",
    "global_home_catalogue_item_name_3": "Market",
    "global_home_section_title_0": "Solutions",
    "global_home_section_subtitle_0": "Solutions",
    "global_home_section_title_1": "Partners",
    "global_home_section_subtitle_1": "Join the industry's finest to co-create the future of 3D fashion.",
    "global_home_solutions_name_0": "Style3D for Brands",
    "global_home_procedure_content_title": "End-to-End Streamlined Process",
    "global_home_procedure_content_subtitle": "End to End",
    "global_home_procedure_roadmap_title_0": "Planning",
    "global_home_procedure_roadmap_subtitle_0": "Planning",
    "global_home_procedure_roadmap_title_1": "Design",
    "global_home_procedure_roadmap_subtitle_1": "Design",
    "global_home_procedure_roadmap_title_2": "Production",
    "global_home_procedure_roadmap_subtitle_2": "Production",
    "global_home_procedure_roadmap_title_3": "Digital Marketing",
    "global_home_procedure_roadmap_subtitle_3": "Digital Marketing",
    "global_home_solutions_des_0": "Tailored design and development solutions to suit brands of all sizes and styles.",
    "global_home_solutions_name_1": "Style3D for Fabric Suppliers",
    "global_home_solutions_des_1": "An all-in-one solution for streamlining textile management, collaboration, and marketing.",
    "global_home_solutions_name_2": "Style3D for Manufacturers",
    "global_home_solutions_des_2": "Empowering your core business scenarios and solutions through digital transformation.",
    "global_home_solutions_name_3": "Style3D for Education",
    "global_home_solutions_des_3": "Our journey begins with industrial innovation and extends to empower academic education.",
    "global_home_user_voice_text_0": "In partnership with Style3D, we've digitalized design resources, maximized design value, and achieved seamless end-to-end collaboration, significantly reducing the development cycle.",
    "global_home_user_voice_text_1": "Digital transformation has become an imperative, recognized by all. Both our company and partners are watching industry trends closely. ANTA is also charting a digital roadmap to grow our business over the next 5 to 10 years. We expect that Style3D will lead the way in the 3D digital transformation.",
    "global_home_user_voice_text_2": "Our fashion heritage is rich, and our early adoption of digital technology has kept us at the forefront. We are highly confident in our digital fashion endeavors, and with Style3D, we're poised to elevate it to the next level!",
    "global_home_user_voice_text_3": "Style3D has demonstrated the transformative power of technology. Together, we will forge a fully digitalized value chain that spans research, development, production, and sales through collaborative efforts.",
    "global_home_header_submenu_link_0_0": "Style3D Studio",
    "global_home_header_submenu_link_0_0_des": "3D garment modeling and animation software.",
    "global_home_header_submenu_link_0_0_des2": "Fuse cloth simulation and AI for a lively 3D fashion experience — a convenient and efficient journey into digital creation.",
    "global_home_header_submenu_link_0_0_des2_title": "Fuse cloth simulation and AI for a lively 3D fashion experience —<br>a convenient and efficient journey into digital creation.",
    "global_home_header_submenu_link_0_0_des3_title": "Fuse cloth simulation and AI for a lively 3D<br>fashion experience — a convenient and efficient<br>journey into digital creation.",
    "global_home_header_submenu_link_0_1": "Style3D Fabric",
    "global_home_header_submenu_link_0_1_des": "Digital fabric creation software, equipped with fabric scanner and testers for acquiring material properties from physical samples.",
    "global_home_header_submenu_link_0_1_des_banner": "Digital fabric creation software, equipped with fabric scanner and testers <br> for acquiring material properties from physical samples.",
    "global_home_header_submenu_link_0_1_des2": "Craft superior digital fabrics effortlessly, skillfully modifying materials<br>and refining physical properties with ease.",
    "global_home_header_submenu_link_0_1_des3": "Craft superior digital fabrics effortlessly, skillfully modifying materials and refining physical properties with ease.",
    "global_home_header_submenu_link_0_1_des2_mobile": "Craft superior digital fabrics effortlessly,<br>skillfully modifying materials and refining<br>physical properties with ease",
    "global_home_header_submenu_link_0_2": "Style3D Cloud",
    "global_home_header_submenu_link_0_2_des": "Online fashion network & ecosystem.",
    "global_home_header_submenu_link_0_2_des2": "Efficiently manage your digital assets and foster seamless collaboration<br>across teams with our one-stop cloud solution.",
    "global_home_header_submenu_link_0_2_des3": "Efficiently manage your digital assets and foster seamless collaboration across teams with our one-stop cloud solution.",
    "global_home_header_submenu_link_0_2_des2_mobile": "Efficiently manage your digital assets and <br>foster seamless collaboration across teams <br> with our one-stop cloud solution.",
    "global_home_header_submenu_link_0_3": "Style3D Atelier&Simulator",
    "global_home_header_submenu_link_0_3_des": "",
    "global_home_header_submenu_link_0_3_des2": "",
    "global_home_header_submenu_link_0_4": "Style3D Market",
    "global_home_header_submenu_link_0_4_des": "Inspiration Library",
    "global_home_header_submenu_link_0_4_des2": "Supercharge your creativity with an extensive library of 3D assets.",
    "global_home_header_submenu_link_0_5": "Style3D Atelier",
    "global_home_header_submenu_link_0_5_des": "3D garment modeling and animation software, tailored for animation, visual effects, and game development.",
    "global_home_header_submenu_link_0_5_des2": "Empower the film, video game, and animation industries with high-performance digital clothing modeling capabilities.",
    "global_home_header_submenu_link_0_5_des3": "Dedicated to digital clothing creation, simulation and visualization.",
    "global_home_header_submenu_link_0_6": "Style3D Simulator",
    "global_home_header_submenu_link_0_6_des": "A high-quality, real-time cloth simulation plugin for Unreal Engine.",
    "global_home_header_submenu_link_0_6_des2": "Real-time simulation and cache recording with high quality results, making digital garments vividly lifelike.",
    "global_home_header_submenu_link_0_6_des3": "Encapsulated as plugins to Unreal engine.",
    "global_home_header_submenu_link_0_8": "Style3D MixMatch",
    "global_home_header_submenu_link_0_8_des": "A real-time, cross-platform app for previewing garment mix-and-match on avatars.",
    "global_home_header_submenu_link_0_8_des2": "Virtual Clothing Styling Output.",
    "global_home_header_submenu_link_0_8_des3": "No need for physical samples, easily use 3D virtual clothing to styling.",
    "global_home_header_submenu_link_0_7": "Style3D One",
    "global_home_header_submenu_link_0_7_des": "A collaborative digital asset management platform for sharing and organization.",
    "global_home_header_submenu_link_0_7_des2": "Designed for fashion industry to put your creation in order.",
    "global_home_header_submenu_link_0_7_des3": "Designed for fashion industry to put your creation in order.",
    "global_home_header_submenu_link_0_7_des2_mobile": "A digital asset management platform designed for fashion industry to put your creation in order.",
    "global_home_header_menu_1": "Solutions",
    "global_home_header_submenu_link_1_0": "Brands",
    "global_home_header_submenu_link_1_0_des": "Tailored design and development solutions to suit brands of all sizes and styles.",
    "global_home_header_submenu_link_1_0_des2": "",
    "global_home_header_submenu_link_1_1": "Manufacturers",
    "global_home_header_submenu_link_1_1_des": "Empowering your core business scenarios and solutions through digital transformation.",
    "global_home_header_submenu_link_1_1_des2": "",
    "global_home_header_submenu_link_1_2": "Fabric Suppliers",
    "global_home_header_submenu_link_1_2_des": "An all-in-one solution for streamlining textile management, collaboration, and marketing.",
    "global_home_header_submenu_link_1_2_des2": "",
    "global_home_header_submenu_link_1_3": "Education",
    "global_home_header_submenu_link_1_3_des": "Our journey begins with industrial innovation and extends to empower academic education.",
    "global_home_header_submenu_link_1_3_des2": "",
    "global_home_header_submenu_link_1_4": "Virtual Fashion",
    "global_home_header_submenu_link_1_4_des": "",
    "global_home_header_submenu_link_1_4_des2": "",
    "global_home_header_menu_2": "Service Support",
    "global_home_header_submenu_link_2_0": "Digital Product Creation",
    "global_home_header_submenu_link_2_0_des": "Comprehensively display apparel and fabric assets.",
    "global_home_header_submenu_link_2_0_des2": "",
    "global_home_header_submenu_link_2_1": "Services",
    "global_home_header_submenu_link_2_1_des": "Assist enterprises in cultivating digital talents.",
    "global_home_header_submenu_link_2_1_des2": "",
    "global_home_header_submenu_link_2_2": "Help Center",
    "global_home_header_submenu_link_2_2_des": "",
    "global_home_header_submenu_link_2_2_des2": "",
    "global_home_header_submenu_link_2_3": "Consultation",
    "global_home_header_submenu_link_2_3_des": "Empower the ecological development and operative models of enterprises.",
    "global_home_header_submenu_link_2_3_des2": "",
    "global_home_header_submenu_link_2_4": "Training",
    "global_home_header_submenu_link_2_4_des": "Assist enterprises to cultivate digital application talents so that digital solutions can be better implemented in enterprises",
    "global_home_header_submenu_link_2_4_des2": "",
    "global_home_header_menu_3": "Resources",
    "global_home_header_submenu_link_3_0": "Learning Center",
    "global_home_header_submenu_link_3_0_des": "",
    "global_home_header_submenu_link_3_0_des2": "",
    "global_home_header_submenu_link_3_1": "Partnership",
    "global_home_header_submenu_link_3_1_des": "Co-create the future of 3D Industry.",
    "global_home_header_submenu_link_3_1_des2": "",
    "global_home_header_submenu_link_3_2": "Customers",
    "global_home_header_submenu_link_3_2_des": "",
    "global_home_header_submenu_link_3_2_des2": "",
    "global_home_header_submenu_link_3_3": "News",
    "global_home_header_submenu_link_3_3_des": "Discover the latest on corporate news, improvements and more.",
    "global_home_header_submenu_link_3_3_des2": "",
    "global_home_header_submenu_link_3_4": "Blog",
    "global_home_header_submenu_link_3_4_des": "Explore the latest on productivity, product news, tips, and more.",
    "global_home_header_submenu_link_3_4_des2": "",
    "global_home_header_submenu_link_3_5": "Success Stories",
    "global_home_header_submenu_link_3_5_des": "Learn how companies like yours do their best work with Style3D.",
    "global_home_header_submenu_link_3_5_des2": "",
    "global_home_header_submenu_link_3_6": "Events",
    "global_home_header_submenu_link_3_6_des": "Join our upcoming online and offline events to unlock new insights.",
    "global_home_header_submenu_link_3_6_des2": "",
    "global_home_header_submenu_link_3_7": "Design Competition",
    "global_home_header_submenu_link_3_7_des": "Recreate Olympic Fashion with Style3D",
    "global_home_header_submenu_link_3_7_des2": "",
    "global_home_header_submenu_link_3_8": "Help Center",
    "global_home_header_submenu_link_3_8_des": "Get instant answers to the most common questions.",
    "global_home_header_submenu_link_3_8_des2": "",
    "global_home_header_submenu_link_3_9": "Tutorials",
    "global_home_header_submenu_link_3_9_des": "Learn how to use Style3D Studio.",
    "global_home_header_submenu_link_3_9_des2": "",
    "global_home_header_menu_4": "About",
    "global_home_header_submenu_link_4_0": "About Us",
    "global_home_header_submenu_link_4_0_des": "Pioneering 3D digital fashion with industry-leading technology.",
    "global_home_header_submenu_link_4_0_des2": "",
    "global_home_header_submenu_link_4_1": "Corporate Social Responsibility",
    "global_home_header_submenu_link_4_1_des": "Innovating with Tech, Designing for Good.",
    "global_home_header_submenu_link_4_1_des2": "",
    "global_home_header_submenu_link_4_2": "Fellowship",
    "global_home_header_submenu_link_4_2_des": "Invest the future and continue to grow.",
    "global_home_header_submenu_link_4_2_des2": "",
    "global_home_header_form_title_1_left": "Contact us",
    "global_home_header_form_title_1_right": "",
    "global_home_header_form_title_2": "Contact us",
    "global_home_header_form_key_1": "Your name",
    "global_home_header_form_placeholder_1": "Please enter your name.",
    "global_home_header_form_key_2": "Phone number",
    "global_home_header_form_placeholder_2": "Please",
    "global_home_header_form_key_3": "Verification Code",
    "global_home_header_form_placeholder_3": "Please enter the verification code.",
    "global_home_header_form_key_4": "E-mail",
    "global_home_header_form_placeholder_4": "Please enter your email address.",
    "global_home_header_form_key_5": "Company name",
    "global_home_header_form_placeholder_5": "Please enter your company name.",
    "global_home_header_form_key_6": "Business type",
    "global_home_header_form_placeholder_6": "Please select your business type.",
    "global_home_header_form_key_7": "Business scale",
    "global_home_header_form_placeholder_7": "Please select your business scale.",
    "global_home_header_form_conform_text": "Read and accept",
    "global_home_header_form_conform_policy": "《Style3D Privacy Policy》",
    "global_home_header_form_help": "Support: sales{'@'}style3d.com",
    "global_home_footer_info_address": "3rd Floor, Building A, Sanshen International Center, 99 Shuanglong Street, Jiangcun Sub-district, Xihu District, Hangzhou, Zhejiang Province.",
    "global_home_footer_info_tel": "Official hotline: 400-158-0699",
    "global_home_footer_info_businessemail": "Business: ",
    "global_home_footer_info_mediaemail": "Media: ",
    "global_home_footer_copyright": "Copyright © 2024 Style3D. All Rights Reserved.",
    "global_swipper_title": "Style3D",
    "global_fabric_colorTitle": "Style3D Fabric",
    "global_fabric_colorTitle1": "",
    "global_fabric_colorTitle2": "",
    "global_fabric_title_0": "Rapid and Precise Fabric Digitization",
    "global_fabric_subTitle_0": "Seamless collaboration between software and hardware for enhanced accuracy in fabric digitization",
    "global_fabric_content_text_00": "Scan & Measure",
    "global_fabric_content_text_01": "Digitalize",
    "global_fabric_content_text_02": "Manage",
    "global_fabric_title_1": "Precise capturing of fabric physical properties",
    "global_fabric_content_img_title_0": "Style3D Scanner",
    "global_fabric_content_text_10": "Precisely captures texture details, presenting clear images and lifelike colors.",
    "global_fabric_content_text_11": "Authentically reproduces various fabric textures, swiftly acquiring multiple PBR texture maps.",
    "global_fabric_content_text_12": "Seamlessly integrates with Style3D Fabric software for intelligent fabric processing.",
    "global_fabric_content_img_title_1": "Style3D Bending Tester & Tensile Tester",
    "global_fabric_content_img_title_1_mobile": "Style3D Bending Tester &<br>Tensile Tester",
    "global_fabric_content_text_13": "Accurately measure weight, thickness, stretch, and bending properties",
    "global_fabric_content_text_14": "Achieve automated fabric measurements without the need for manual input",
    "global_fabric_content_text_15": "Scientifically analyze fabric characteristics for a true-to-life representation",
    "global_fabric_content_text_16": "Faithfully represent the properties of real fabrics.",
    "global_fabric_title_2": "Real-Time Editing and Preview of Digital Fabrics",
    "global_fabric_subTitle_2": "Swiftly edit and fine-tune digital fabrics, with real-time previews for an instant on-body effect",
    "global_fabric_content_text_26": "Explore multi-dimensional fabric properties",
    "global_fabric_content_text_27": "One-click fabric looping, colorway adjustments, and color swapping",
    "global_fabric_content_text_28": "Supports both real-time and offline rendering modes",
    "global_fabric_content_text_29": "Witness the instant presentation of highly realistic fabrics on Avatars",
    "global_fabric_content_text_20": "Base Color Map",
    "global_fabric_content_text_21": "Normal Map",
    "global_fabric_content_text_22": "Transparency Map",
    "global_fabric_content_text_23": "Metallic Map",
    "global_fabric_content_text_24": "Roughness Map",
    "global_fabric_content_text_25": "Displacement Map",
    "global_one_title_0": "Organize it your way for full control",
    "global_one_subTitle_0": "Build your library from 0 to 1 by standardizing asset type, category, keyword, tab, format and more.",
    "global_one_title_1": "Find what you need in one click",
    "global_one_subTitle_1": "Search with images，tags or keywords to find what you need smartly.",
    "global_one_title_2": "Compatible with various 2D&3D formats",
    "global_one_subTitle_2": "Supported multiple 2D and 3D asset types for greater flexibility, collaboration, and future-proofing.",
    "global_studio_title_3": "Swift Creation of Digital Garment",
    "global_studio_subTitle_3": "3D visualization of fabrics, patterns, and Avatars with real-time editing for stunning garment effects",
    "global_studio_title_4": "AI-Powered Excellence: Effortlessly Restore Garment and Avatar Realism",
    "global_studio_subTitle_4": "Utilize AI tools to authentically recreate model appearances and precisely simulate fabric details",
    "global_studio_title_5": "Unveil Stunning Garment Simulation",
    "global_studio_subTitle_5": "Utilize tools like real-time rendering to quickly and accurately generate 3D garment visuals",
    "global_studio_title_6": "Seamless Collaboration Across Software",
    "global_studio_subTitle_6": "Connects with various 2D/3D software, freeing your design creativity",
    "global_studio_title_7": "Precise Craftsmanship Reproduction",
    "global_studio_subTitle_7": "Leveraging proprietary fabric simulation technology and diverse craftsmanship tools for meticulous recreation of genuine garment craftsmanship",
    "global_studio_title_8": "Unleash Visual Creativity",
    "global_studio_subTitle_8": "Break free from creative constraints and elevate your brand promotion to new heights",
    "global_studio_FAQs": "",
    "global_studio_question_0": "Q: How do I download the Software?",
    "global_studio_answertext_0": "A: Click on the ",
    "global_studio_answertext_1": "\"Apply for Trial\"",
    "global_studio_answertext_2": " button in the top right corner of the page.",
    "global_studio_question_1": "Q: What are the recommended specifications for Style3D Studio?",
    "global_studio_answer_1": "A: Please refer to the <a target=\"_blank\" href='https://www.style3d.com/public/helper/detail/grid/style3dsoftware/1202'>hardware configuration requirements.</a>",
    "global_studio_question_2": "Q: How can I find out more product information?",
    "global_studio_answer_2": "A: Please visit our <a target=\"_blank\" href='https://www.style3d.com/public/helper/detail/grid/style3dsoftware/1173'>User Manual.</a>",
    "global_cloud_title_0": "Enhanced efficient collaboration",
    "global_cloud_subTitle_0": "Improve the effectiveness of your ecosystem, both online and offline.",
    "global_cloud_title_1": "Smarter asset management",
    "global_cloud_subTitle_1": "Store, search, edit and showcase your 3D assets in one place.",
    "global_cloud_title_2": "Bring 3D assets to life",
    "global_cloud_subTitle_2": "Showcase 3D effects in multiple cloud rendering scenarios.",
    "global_cloud_title_3": "Easier design collaboration",
    "global_cloud_subTitle_3": "Communicate design details in real time, and edit styles with a single click.",
    "global_cloud_performance_title_0": "Openness",
    "global_cloud_performance_subTitle_0": "Facilitating system integration through SDKs and APIs",
    "global_cloud_performance_title_1": "Compatibility",
    "global_cloud_performance_subTitle_1": "Supporting industry-standard 3D software asset formats such as OBJ, FBX, GITF, GLB, SBSAR, DXF, and more",
    "global_cloud_performance_title_2": "Security",
    "global_cloud_performance_subTitle_2": "Globally certified secure cloud services with TLS & AWS",
    "global_cloud_performance_title_3": "Cross-platform",
    "global_cloud_performance_subTitle_3": "Available on Mobile, PC, and Mac",
    "global_atelier_title_0": "A powerful 3D visual effects software dedicated to digital clothing creation, simulation and visualization.",
    "global_atelier_content_0": "Reliable and Efficient Simulation of Dressed Characters",
    "global_atelier_content_0_subtext": "Simulate a soft body avatar dressed in multi-layered garments within seconds per frame.",
    "global_atelier_content_1": "Cinema-level Visual Quality",
    "global_atelier_content_1_subtext": "Simulate incredibly intricate clothing details down to the sub-millimeter level.",
    "global_atelier_content_2": "Convenient and Comprehensive Toolkits for Game Developers",
    "global_atelier_content_2_subtext": "Provide rigging, editing and export features to multiple environments.",
    "global_atelier_video_des_0": "Clothing creation by: Zero, Light rendering by: Cat_Aven",
    "global_atelier_video_text": "Self-developed GPU simulation for smooth and efficient performance. An easy and fast solution for 5-7 layer fabric garments.",
    "global_simulator_title_0": "An industry-Level clothing simulation engine,<br>encapsulated as plugins to Unreal engine",
    "global_simulator_content_0": "Full Compatibility with Style3D Digital Assets",
    "global_simulator_content_0_subtext": "Animate digital clothing created by Style3D Atelier in Unreal Engine.",
    "global_simulator_content_1": "Real-Time Clothing Simulation, Powered by Cutting-edge CG Technology",
    "global_simulator_content_1_subtext": "Simulate complex clothing with more than 50K vertices in real time.",
    "global_simulator_content_2": "Animation Caching",
    "global_simulator_content_2_subtext": "Allow superior-quality clothing animation to be recorded in a compact format for postprocessing and display.",
    "global_simulator_video_text": "Real-time simulation of multi-layered clothing. With <span class='color'>50,000</span> vertex face garments and live simulation of multi-layer fabric garments, the frame rate can still be maintained at more than <span class='color'>45FPS+</span>.",
    "global_studio_inspiration_card_0": "In-house. GPU-based. State-of-the-art. Empowered by innovative technology, our engine runs <span class='color'> up to 4x faster than its counterparts.</span>",
    "global_studio_inspiration_card_1": "Proprietary deformable <span class='color1'>body simulation algorithms.</span>",
    "global_studio_inspiration_card_2": "Cross-platform Rendering with Vulkan APIs.",
    "global_studio_inspiration_card_3": "Dependable and efficient rendering of complex content, including fur.",
    "global_studio_inspiration_cpu": "CPU performance",
    "global_studio_inspiration_gpu": "GPU performance",
    "global_studio_number_card_0": "An expanding resource market providing fabrics, garment blocks, and more.",
    "global_studio_number_card_1": "Support complex techniques, including embroidery, advanced textures, and offset printing.",
    "global_studio_number_card_2": "Enhance your presentations and line reviews with customizable rendering and animation.",
    "global_studio_number_card_3": "A wide array of avatars, adaptable to different sizes, poses, and facial expressions.",
    "global_studio_process_card_0": "Strengthen your teamwork on PC, Mac, or in the cloud.",
    "global_studio_process_card_1": "Validate ideas and patterns without delays.",
    "global_studio_process_card_2": "Compatible with mainstream 2D CAD software, facilitating a seamless transition from design to production.",
    "global_studio_colorTitle": "Style3D Studio",
    "global_studio_title_0": "Realistic, fast and appealing 3D visualization",
    "global_studio_subTitle_0": "Communicate details in real time, edit styles with just one click.",
    "global_studio_title_1": "Unleash limitless creativity through effortless 3D creation",
    "global_studio_subTitle_1": "A wealth of inspiration resources",
    "global_studio_title_2": "A variety of materials and customized avatars",
    "global_atelier_colorTitle_0": "Style3D Atelier",
    "global_atelier_colorTitle_1": "Style3D Simulator",
    "global_atelier_swipper_colorTitle_3": "Atelier & Simulator",
    "global_atelier_swipper_subTitle_3": "用Style3D Atelier&Meta创造惊艳的 3D 服装效果",
    "global_atelier_swipper_purpletext": "Atelier",
    "global_atelier_swipper_greentext": "Simulator",
    "global_atelier_swipper_whitetext": "无论是游戏、动画、电影还是其他CG项目，都可以用这套工具集轻松创建、模拟、渲染各种服装。",
    "global_atelier_tool_title": "专业的数字服装建模工具<br>创建<span class='gradientText'>高质量、超现实</span>的数字服装",
    "global_atelier_tool_card_title_0": "快速和简单",
    "global_atelier_tool_card_subtitle_0": "无需复杂的操作技巧，Atelier直观、强大的工具帮你在几分钟内快速完成一件服装的设计调整和建模。",
    "global_atelier_tool_card_title_1": "逼真和精细",
    "global_atelier_tool_card_subtitle_1": "搭载高性能GPU模拟布料的重量、摩擦、弹性等属性，高仿真还原服装款式细节、材质。",
    "global_atelier_tool_card_title_2": "兼容性和灵活性",
    "global_atelier_tool_card_subtitle_2": "兼容主流3D格式，例如 FBX、OBJ、ABC、SMD等，与UV编辑器、NVIDIA、Omniverse平台无缝连接。",
    "global_atelier_plugin_title": "虚幻引擎实时服装动画插件<br>轻松<span class='gradientText'>提升工作效率</span>和<span class='gradientText2'>创意表达</span>",
    "global_atelier_plugin_card_title_0": "建模-动画链路提速<span class='colorText'>70%</span>",
    "global_atelier_plugin_card_subtitle_0": "联动Style3D Atelier建模工具，通过Meta进行高效缓存和渲染，以更短时间获得更高的画质和性能，<br>更好地展示你的作品。",
    "global_atelier_plugin_card_title_1": "实时仿真，可支持多角色同时驱动",
    "global_atelier_plugin_card_subtitle_1": "对多层布料、大幅度动作等复杂场景进行实时解算，无需等待烘焙或导出，即刻预览和调整服装<br>的效果和动作。",
    "global_atelier_plugin_card_title_2": "兼容性和灵活性",
    "global_atelier_plugin_card_subtitle_2": "通过鼠标可以灵活拖拽服装，查看服装的实时变化，并且同一款服装可以自动匹配不同身型，呈现相<br>应的上身效果。",
    "global_atelier_card_button1": "下载试用版",
    "global_atelier_card_button2": "配置要求",
    "global_mixmatch_title_0": "Infinite styling, unleash your creativity",
    "global_mixmatch_subTitle_0": "With the help of our self-developed simulation engine, you can easily create fashion outfits of various styles, layers, and ways of dressing.",
    "global_mixmatch_title_1": "Massive resources, display as you wish",
    "global_mixmatch_subTitle_1": "We equipped various types of models, accessories, poses, scenes, and other resources for your free choice.",
    "global_mixmatch_title_2": "Multiple modes of adaptation, extending application scenarios",
    "global_mixmatch_subTitle_2": "PC mode, mobile mode, big screen mode, vertical screen mode, etc., to adapt to your diverse application scenarios.",
    "global_mixmatch_title_3": "Product advantages",
    "global_mixmatch_title_4": "Application Scenario",
    "global_mixmatch_collocation_text_0": "Changing layers",
    "global_mixmatch_collocation_text_1": "Adjusting styles",
    "global_mixmatch_mode_title_0": "Vertical Screen Mode ",
    "global_mixmatch_mode_title_1": "Mobile End",
    "global_mixmatch_mode_text_0": "Elevated visual effects",
    "global_mixmatch_mode_text_1": "Live experience interaction upgrade ",
    "global_mixmatch_mode_text_2": "Styling & try on effects are readily available",
    "global_mixmatch_mode_text_3": "Get outfit and size recommendations",
    "global_mixmatch_advantages_title_0": "No need for physical samples",
    "global_mixmatch_advantages_title_1": "Flexible and Efficient",
    "global_mixmatch_advantages_title_2": "Lower cost",
    "global_mixmatch_advantages_title_3": "Simple operation ",
    "global_mixmatch_advantages_follow_0": "You can start styling without waiting for physical samples.",
    "global_mixmatch_advantages_follow_1": "Fast outfit styling, an average of 30 sets of clothing matches can be completed in 10 minutes.",
    "global_mixmatch_advantages_follow_2": "0 model fees, 0 venue fees.",
    "global_mixmatch_advantages_follow_3": "No 3D basic knowledge required, get started in 3 minutes.",
    "global_mixmatch_scenarios_title_0": "Design R&D",
    "global_mixmatch_scenarios_title_1": "Ordering Meeting",
    "global_mixmatch_scenarios_title_2": "Guidance for Store Styling",
    "global_mixmatch_scenarios_title_3": "E-commerce shooting",
    "global_mixmatch_scenarios_subTitle_0": "Enables fashion designers to output high-quality matching solutions efficiently.",
    "global_mixmatch_scenarios_subTitle_1": "Enhance on-site interaction, bringing new traffic and orders.",
    "global_mixmatch_scenarios_subTitle_2": "Empower stylists and display stylists to efficiently output styling solutions, providing more styling guide information for stores.",
    "global_mixmatch_scenarios_subTitle_3": "Prepare for shooting styling solutions in advance.",
    "global_solutions_edu_topic_title": "Style3D for Education",
    "global_solutions_edu_topic_subtitle": "",
    "global_solutions_edu_title_0": "End-to-End Digital Talent Development<br>for Textile and Apparel Supply Chain",
    "global_solutions_edu_title_1": "Talent Development Solutions",
    "global_solutions_edu_part_1_item_title_1": "Design",
    "global_solutions_edu_part_1_item_subtitle_11": "Digital fashion planning",
    "global_solutions_edu_part_1_item_subtitle_12": "Graphic designer",
    "global_solutions_edu_part_1_item_subtitle_13": "Fashion designer",
    "global_solutions_edu_part_1_item_title_2": "Production",
    "global_solutions_edu_part_1_item_subtitle_21": "Digital patternmaker",
    "global_solutions_edu_part_1_item_subtitle_22": "3D modeler",
    "global_solutions_edu_part_1_item_subtitle_23": "Digital specialist",
    "global_solutions_edu_part_1_item_title_3": "Operation",
    "global_solutions_edu_part_1_item_subtitle_31": "Digital project manager",
    "global_solutions_edu_part_1_item_subtitle_32": "Digital product manager",
    "global_solutions_edu_part_3_item_centerText": "Digital bridge between universities and fashion companies",
    "global_solutions_edu_title_2": "Discipline-Building Solutions",
    "global_solutions_edu_part_2_text1": "Partnership between industry and university",
    "global_solutions_edu_part_2_text2": "Digital fashion resource platform",
    "global_solutions_edu_part_2_text3": "Digital fashion lab/training center",
    "global_solutions_edu_part_2_text4": "Metaverse fashion show center",
    "global_solutions_edu_part_2_text5": "Digital fashion simulation project",
    "global_solutions_edu_part_2_text6": "Digital solutions for fabrics",
    "global_solutions_edu_title_3": "Digital Course Resources",
    "global_solutions_edu_part_3_item_title_1": "Specialized <br> courses",
    "global_solutions_edu_part_3_item_subtitle_11": "Connecting learning to business objectives",
    "global_solutions_edu_part_3_item_subtitle_12": "Practice-based curriculum",
    "global_solutions_edu_part_3_item_subtitle_13": "Learning platform and huge resources",
    "global_solutions_edu_part_3_item_subtitle_14": "Competency assessment",
    "global_solutions_edu_part_3_item_title_2": "Modular <br> course structure",
    "global_solutions_edu_part_3_item_subtitle_21": "Digital development of suits/dresses/jeans/down jackets/accessories",
    "global_solutions_edu_part_3_item_subtitle_22": "Ready-to-wear fashion show",
    "global_solutions_edu_part_3_item_subtitle_23": "Presentation",
    "global_solutions_edu_part_3_item_title_3": "Comprehensive <br> teaching resources",
    "global_solutions_edu_part_3_item_subtitle_31": "Course objectives & textbooks",
    "global_solutions_edu_part_3_item_subtitle_32": "Capstone curriculum design with case studies",
    "global_solutions_edu_part_3_item_subtitle_33": "Course materials, videos and quizzes",
    "global_solutions_edu_part_3_item_title_4": "Training",
    "global_solutions_edu_part_3_item_subtitle_41": "Teacher training",
    "global_solutions_edu_part_3_item_subtitle_42": "Professsional training",
    "global_solutions_edu_part_3_item_subtitle_43": "Student training",
    "global_solutions_edu_part_3_item_subtitle_44": "Assessment and certification",
    "global_solutions_edu_part_3_item_title_5": "Academic/Skill Competitions",
    "global_solutions_edu_part_3_item_subtitle_51": "Host a competition",
    "global_solutions_edu_part_3_item_subtitle_52": "Organize a competition",
    "global_solutions_edu_part_3_item_subtitle_53": "Guidance for participants",
    "global_solutions_edu_part_3_item_title_6": "Value-added service",
    "global_solutions_edu_part_3_item_subtitle_61": "Research collaboration",
    "global_solutions_edu_part_3_item_subtitle_62": "Industry expert lectures",
    "global_solutions_edu_part_3_item_subtitle_63": "Career workshop",
    "global_solutions_edu_title_4": "Academic partnership",
    "global_solutions_edu_part_4_description": "Co-create the future of 3D fashion through education",
    "global_solutions_brand_swipper_title": "Style3D for Brands",
    "global_solutions_brand_swipper_subtitle": "",
    "global_solutions_brand_swipper_title_0": "Industry Challenges",
    "global_solutions_brand_swipper_card_title_0": "Non-intuitive planning",
    "global_solutions_brand_swipper_card_subtitle_0": "Valuable time is spent on manual data collection, rather than on planning and design.",
    "global_solutions_brand_swipper_card_text_0": "Digital Planning",
    "global_solutions_brand_swipper_card_title_1": "Low efficiency of R&D review",
    "global_solutions_brand_swipper_card_subtitle_1": "Frequent adjustments to samples consume a significant amount of time and resouces.",
    "global_solutions_brand_swipper_card_text_1": "Digital R&D collaboration",
    "global_solutions_brand_swipper_card_title_2": "Limited assortment",
    "global_solutions_brand_swipper_card_subtitle_2": "Short timelines and long shipping delays hinder comprehensive style assessments and approvals.",
    "global_solutions_brand_swipper_card_text_2": "Digital assortment",
    "global_solutions_brand_swipper_card_title_3": "Costly Go-to-Market",
    "global_solutions_brand_swipper_card_subtitle_3": "Traditional marketing drains precious time and resources amid tight deadlines.",
    "global_solutions_brand_swipper_card_text_3": "Digital Marketing",
    "global_solutions_brand_swipper_card_title_4": "Planning",
    "global_solutions_brand_swipper_card_subtitle_4": "Data collection by hand takes time away from planning and design.",
    "global_solutions_brand_swipper_card_title_5": "Development",
    "global_solutions_brand_swipper_card_subtitle_5": "Frequent sample adjustment costs time and resources.",
    "global_solutions_brand_swipper_card_title_6": "Fitting review & approval",
    "global_solutions_brand_swipper_card_subtitle_6": "Short schedules and extended shipment delays make approval difficult.",
    "global_solutions_brand_swipper_card_title_7": "Go-to-market",
    "global_solutions_brand_swipper_card_subtitle_7": "Traditional marketing is time-consuming and deadline-driven.",
    "global_solutions_brand_Arrows_title_1": "Endless inspiration, fueled by digitalization",
    "global_solutions_brand_Arrows_subtitle_1": "Integrate trendy elements and inspiring materials to speed up the planning process.",
    "global_solutions_brand_Arrows_titleCN_1": "Solution for Plan&Design",
    "global_solutions_brand_Arrows_rightText_1_1": "Cloud collaboration platform with strong compatibility and editing capability enables ideas visible, specific,  promoting efficient design process.",
    "global_solutions_brand_Arrows_rightText_1_2": "Frequently updated recommendations keeping track of fashion trends help brands acquire fashion insights.",
    "global_solutions_brand_Arrows_rightText_1_3": "Massive exquisite templates to showcase intentions of  plans and creative ideas.",
    "global_solutions_brand_Arrows_title_2": "Speed up development, collaborate in real time",
    "global_solutions_brand_Arrows_subtitle_2": "Adjust and showcase fabrics, patterns, and samples instantly.",
    "global_solutions_brand_Arrows_titleCN_2": "Solution for R&D and Review ",
    "global_solutions_brand_Arrows_rightText_2_1": "Different sorts of products created based on AIGC carry brands’ original genes and unleash creative insights.",
    "global_solutions_brand_Arrows_rightText_2_2": "Automated sewing and CAD-based prototypes elevate the R&D efficiency and reduce costs in making physical samples",
    "global_solutions_brand_Arrows_rightText_2_3": "Cutting-edge simulating technology accurately display fabric design and clothing style design. ",
    "global_solutions_brand_Arrows_title_3": "Improve efficiency with 3D reviews & approvals",
    "global_solutions_brand_Arrows_subtitle_3": "Make more informed decisions with true-to-life digital samples.",
    "global_solutions_brand_Arrows_titleCN_3": "Solution for Assortment",
    "global_solutions_brand_Arrows_rightText_3_1": "3D prototype  elevates the efficiency of fitting and review.",
    "global_solutions_brand_Arrows_rightText_3_2": "360° immersive virtual fittings enhance the try-on experience, elevate product image and cross-selling, boost R&D efficiency, and reduce sample clothing costs",
    "global_solutions_brand_Arrows_rightText_3_3": "Conducting structural analysis to accurately manage the pace of product listings enhances merchandise control capabilities.",
    "global_solutions_brand_Arrows_title_4": "Instant new arrivals in digital marketing & e-commerce",
    "global_solutions_brand_Arrows_subtitle_4": "Embrace the future of fashion by creating breathtaking visuals that captivate customers.",
    "global_solutions_brand_Arrows_titleCN_4": "Solution for Go-to-Market",
    "global_solutions_brand_Arrows_rightText_4_1": "3D catwalk empowers marketing with low costs but high returns. ",
    "global_solutions_brand_Arrows_rightText_4_2": "Short videos  about all HD rendering details allow classical fashion to be seen,  streamlining marketing process.",
    "global_solutions_brand_Arrows_rightText_4_3": "Standardized full-set and full-chain image generating pattern makes product updating easier.",
    "global_solutions_brand_titleimage_1": "Benefits",
    "global_solutions_brand_titleimage_2": "3D End-to-End",
    "global_solutions_brand_followimage_2": "3D digital technology replaces more physical design prototyping, reducing R&D costs, promoting low-carbon environmental protection, and helping businesses to reduce costs and increase efficiency.<br>Through the use of 3D assets for visual presentation, it enhances product competitiveness and attractiveness, boosting product sales and customer acquisition revenue",
    "global_solutions_brand_followimage_moblie_2": "3D digital technology replaces more physical design prototyping, reducing R&D costs, promoting low-carbon environmental protection, and helping businesses to reduce costs and increase efficiency. Through the use of 3D assets for visual presentation, it enhances product competitiveness and attractiveness, boosting product sales and customer acquisition revenue",
    "global_solutions_brand_titleimage_3": "Solution Matrix",
    "global_solutions_brand_chainImg_title": "Build a brand-centered digital ecosystem",
    "global_solutions_brand_panoramagram_title_1": "3D Fashion Panorama",
    "global_solutions_brand_panoramagram_title_2": "Digitally connecting the customer journey to create elite sales force",
    "global_solutions_brand_panoramagram_title_3": "Digitally empowering sales channels to create elite sales representatives, opening new opportunities for enterprises",
    "global_solutions_brand_panoramagram_subtitle_1": "A collaborative digital ecosystem bringing together fashion brands, fabric suppliers, and manufacturers.",
    "global_solutions_fabric_panoramagram_subtitle_1": "A collaborative digital ecosystem bringing together fabric suppliers, fashion brands, <br>and manufacturers.",
    "global_solutions_manufacturer_swipper_title": "Style3D for Manufacturers",
    "global_solutions_manufacturer_swipper_subtitle": "",
    "global_solutions_manufacturer_swipper_card_title_0": "Asset management",
    "global_solutions_manufacturer_swipper_card_subtitle_0": "Large inventories, slow style selection.",
    "global_solutions_manufacturer_swipper_card_title_1": "R&D collaboration",
    "global_solutions_manufacturer_swipper_card_subtitle_1": "Inefficient collaborative efforts, resulting in time and money wasted on samples.",
    "global_solutions_manufacturer_swipper_card_title_2": "Customer acquisition",
    "global_solutions_manufacturer_swipper_card_subtitle_2": "The limitations of traditional marketing strategies leading to lost leads.",
    "global_solutions_manufacturer_Arrows_title_1": "Digitalize Design Center",
    "global_solutions_manufacturer_Arrows_subtitle_1": "Digital Assets",
    "global_solutions_manufacturer_Arrows_describe_1": "Upload your 2D and 3D assets to the cloud.",
    "global_solutions_manufacturer_Arrows_title_2": "Accumulate Digital Assets ",
    "global_solutions_manufacturer_Arrows_subtitle_2": "3D Collaboration",
    "global_solutions_manufacturer_Arrows_describe_2": "Unified digital space with brand consistency.",
    "global_solutions_manufacturer_Arrows_describe_3": "Digital showroom and company website",
    "global_solutions_manufacturer_Arrows_title_3": "Upgrade Corporate Website with Realistic VR Showroom",
    "global_solutions_manufacturer_Arrows_describe_5": "Digital Fabric Sample",
    "global_solutions_manufacturer_Arrows_describe_9": "* Taking the production of down jackets in five different colors as an example",
    "global_solutions_manufacturer_Arrows_describe_6": "Efficient 3D Sampling Room",
    "global_solutions_manufacturer_Arrows_describe_7": "Garment Showroom with One Item, One Code",
    "global_solutions_manufacturer_Arrows_describe_8": "One-stop fabric solution, seamlessly integrated software and hardware.",
    "global_solutions_manufacturer_Arrows_subtitle_3": "Omni-Channel Marketing",
    "global_solutions_manufacturer_Arrows_describe_4": "QR code assigned to each SKU, providing a link to its digital twin",
    "global_solutions_manufacturer_panoramagram_title_1": "",
    "global_solutions_manufacturer_panoramagram_subtitle_1": "",
    "global_solutions_manufacturer_Arrows_title_4": "Offline Garment Showroom with One Item One Code",
    "global_solutions_manufacturer_Arrows_title_5": "Make sample management a breeze",
    "global_solutions_manufacturer_arrows_video_bottom_text": "Efficient development through the joint use of real and virtual samples.",
    "global_solutions_manufacturer_arrows_card_title_1": "Development & Design.",
    "global_solutions_manufacturer_arrows_card_title_2": "Editing styles.",
    "global_solutions_manufacturer_arrows_card_title_3": "Change and generate new colorways.",
    "global_solutions_fabric_swipper_title": "Style3D for Fabric Suppliers",
    "global_solutions_fabric_swipper_subtitle": "",
    "global_solutions_fabric_swipper_card_title_0": "Asset management",
    "global_solutions_fabric_swipper_card_subtitle_0": "Inefficient inventory management; time-consuming fabric sorting and selection.",
    "global_solutions_fabric_swipper_card_title_1": "R&D collaboration",
    "global_solutions_fabric_swipper_card_subtitle_1": "Lengthy development cycles; slow sample review and costly shipping.",
    "global_solutions_fabric_swipper_card_title_2": "Customer acquisition",
    "global_solutions_fabric_swipper_card_subtitle_2": "Limited product showcase of fabrics and styles through traditional methods leading to lost business.",
    "global_solutions_fabric_Arrows_title_1": "Digitalize Fabric Workstation",
    "global_solutions_fabric_Arrows_describe_1": "Elevate fabric presentation through synergistic hardware and software integration.",
    "global_solutions_fabric_Arrows_describe_2": "Recommend fabric trendbooks to customers prior to shipping samples.",
    "global_solutions_fabric_Arrows_title_2": "Upgrade Fabric Sample and Trendbook",
    "global_solutions_fabric_Arrows_describe_3": "Allow clients to review digital fabrics before receiving physical samples.",
    "global_solutions_fabric_Arrows_describe_4": "Real-time online editing.",
    "global_solutions_fabric_Arrows_title_3": "Make \"One Item, One Code\" Available at Offline Showroom",
    "global_solutions_fabric_Arrows_describe_5": "Facilitate a seamless transition between physical and virtual worlds with digital fabric showrooms.",
    "global_solutions_fabric_Arrows_describe_6": "Explore and analyze fabric material properties in diverse and creative ways.",
    "global_solutions_fabric_Arrows_title_4": "Upgrade Corporate Website",
    "global_solutions_fabric_Arrows_title_5": "Make Fabric Inventory Management Clear at A Glance",
    "global_solutions_fabric_panoramagram_title_1": "",
    "global_support_customer_success_swipper_title": "Training Service",
    "global_support_customer_success_swipper_subtitle": "",
    "global_support_consultation_header_title": "Style3D, with \"AI + 3D\" technology at its core, is dedicated to driving the digital transformation of the global fashion industry. We help customers develop and execute digital strategies, digitize design, supply chain, and marketing operations, fostering efficient and sustainable enterprise development.",
    "global_support_consultation_service_title_1": "Advantages of Consulting Service",
    "global_support_consultation_service_title_2": "Service Content and Procedure",
    "global_support_consultation_service_title_3": "Scenarios for Businesses",
    "global_support_consultation_service_content_1_title_1": "Driven by technological innovation",
    "global_support_consultation_service_content_1_follow_1": "Empower innovative development and businessgrowth with technology as the core driving force.",
    "global_support_consultation_service_content_1_title_2": "Seasoned industry experts",
    "global_support_consultation_service_content_1_follow_2": "Seasoned industry experts with top-tier enterprise background provide comprehensive business support.",
    "global_support_consultation_service_content_1_title_3": "End-to-end personalized service",
    "global_support_consultation_service_content_1_follow_3": "Throughout the project lifecycle, comprehensive consulting services are provided every step of the way.",
    "global_support_consultation_service_content_3_title_1": "For Manufacturers",
    "global_support_consultation_service_content_3_title_2": "For Brands",
    "global_support_customer_success_title_0": "Features",
    "global_support_customer_success_content_0_title_0": "Systemic customization",
    "global_support_customer_success_content_0_follow_0": "We conduct pre-class offline research to tailor modular training to the specific needs of enterprises, ensuring the delivery of a comprehensive and systematic training program.",
    "global_support_customer_success_content_0_title_1": "Flexible time",
    "global_support_customer_success_content_0_follow_1": "We offer both online and offline courses to accommodate customer's schedules.",
    "global_support_customer_success_content_0_title_2": "1v1 training<br>in depth",
    "global_support_customer_success_content_0_follow_2": "We provide 1v1 intensive training and coaching for 3 to 6 months, complemented by a one-year service to assist our customers in mastering the modeling skills necessary for their projects.",
    "global_support_customer_success_content_0_title_3": "Dual certificates",
    "global_support_customer_success_content_0_follow_3": "Two directions: modeling and visual effects, tailored to diverse business needs.",
    "global_support_customer_success_content_0_title_4": "Focused training",
    "global_support_customer_success_content_0_follow_4": "We regularly organize intensive training sessions, both online and offline.",
    "global_support_customer_success_content_0_title_5": "Multiple categories",
    "global_support_customer_success_content_0_follow_5": "Our courses encompass essential categories within the fashion and accessories industry, offering tailored programs aligned with specific product categories.",
    "global_support_customer_success_title_1": "3D Skill Development Pathway",
    "global_support_customer_success_content_1_title_0": "Modeler",
    "global_support_customer_success_content_1_subtitle_0": "Courses for all stages",
    "global_support_customer_success_content_1_follow_0": "Our modeling skills are categorized into three levels: beginner, intermediate, and advanced, with specialization options in modeling or visual effects. This flexible approach caters to diverse learning stages, application scenarios, and business requirements.",
    "global_support_customer_success_content_1_title_1": "Pattern designer",
    "global_support_customer_success_content_1_subtitle_1": "3D skill development pathway",
    "global_support_customer_success_content_1_follow_1": "When a pattern designer enters the realm of 3D business applications, we tailor a personalized learning path to equip them with the essential 3D skills they need.",
    "global_support_customer_success_content_1_title_2": "Designer",
    "global_support_customer_success_content_1_subtitle_2": "3D skill development pathway",
    "global_support_customer_success_content_1_follow_2": "When a designer enters the realm of 3D business applications, we tailor a personalized learning path to equip them with the essential 3D skills they need.",
    "global_support_customer_success_content_1_title_3": "Assessment criteria",
    "global_support_customer_success_content_1_subtitle_3": "A comprehensive assessment criteria",
    "global_support_customer_success_content_1_follow_3": "We develop precise assessment plans and standards for various roles, stages, and specializations, facilitating accurate evaluations of the entire team.",
    "global_support_customer_success_title_2": "Internal Mentorship Empowerment",
    "global_support_customer_success_content_2_title_0": "Talent development",
    "global_support_customer_success_content_2_follow_0": "We assist in cultivating technicians with 3D training and support capabilities, establishing a sustainable foundation for 3D applications.",
    "global_support_customer_success_content_2_title_1": "System establishment",
    "global_support_customer_success_content_2_follow_1": "We help build an internal 3D skill assessment system to enhance in-house management of 3D applications.",
    "global_support_customer_success_title_3": "Integrate Scenarios with Applications",
    "global_support_customer_success_content_3_title_0": "Digital asset accumulation",
    "global_support_customer_success_content_3_follow_0": "Our program aligns with a company's 3D asset accumulation requirements, preparing them to establish their own 3D asset library.",
    "global_support_customer_success_content_3_title_1": "Digital development",
    "global_support_customer_success_content_3_follow_1": "Our program aligns with a company's business needs, facilitating precise development and 3D reviews, thereby establishing a robust foundation for in-depth 3D digital R&D.",
    "global_support_customer_success_content_3_title_2": "Visual marketing",
    "global_support_customer_success_content_3_follow_2": "Our courses address various visual effects needs, encompassing posters, catwalk animations, and other visual outputs that can be applied in e-commerce product launches and marketing promotions.",
    "global_support_contentservices_swipper_title": "Digital Product Creation Service",
    "global_support_contentservices_swipper_subtitle": "",
    "global_support_consultationservice_swipper_title": "Consulting Service",
    "global_support_consultationservice_swipper_subtitle": "",
    "global_support_contentservices_info_title_0": "3D Modeling",
    "global_support_contentservices_info_subtitle_0": "3D Modeling",
    "global_support_contentservices_info_text_00": "Project file",
    "global_support_contentservices_info_text_01": "Real-time model",
    "global_support_contentservices_info_title_1": "Real-Time Rendering",
    "global_support_contentservices_info_subtitle_1": "3D Rendering",
    "global_support_contentservices_info_text_10": "One item & one item 360°",
    "global_support_contentservices_info_text_11": "Suit & suit 360°",
    "global_support_contentservices_info_title_2": "3D Animation",
    "global_support_contentservices_info_subtitle_2": "3D Animation",
    "global_support_contentservices_info_text_20": "In-place animation",
    "global_support_contentservices_info_text_21": "Template animation",
    "global_support_contentservices_info_text_22": "Creative animation",
    "global_support_contentservices_info_text_23": "Naked-eye 3D display",
    "global_support_contentservices_info_title_3": "Fabric",
    "global_support_contentservices_info_subtitle_3": "Fabric",
    "global_support_contentservices_info_text_30": "Fabric display",
    "global_support_contentservices_info_text_31": "Fabric rendering",
    "global_support_contentservices_info_text_32": "Digital fabric",
    "global_support_contentservices_info_title_4": "Comprehensive representation",
    "global_support_contentservices_info_subtitle_4": "Comprehensive Representation",
    "global_support_contentservices_info_text_40": "Digital moodboard",
    "global_support_contentservices_info_text_41": "Corporate exhibition hall",
    "global_support_contentservices_info_text_42": "VR Showroom",
    "global_aboutus_introduction_swipper_title": "Welcome to Style3D",
    "global_aboutus_introduction_swipper_card_top_text_top": "Style3D is a science-based company, dedicated to offering 3D+AI tools for creating, displaying, and collaborating on digital assets, propelling the global fashion industry's digital and innovative evolution.",
    "global_aboutus_introduction_swipper_card_top_text_bottom": "Pioneering digital technology to propel the future of fashion",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des1": "Customer-first",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des2": "Dare to go beyond",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des3": "Diverse collaboration",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des4": "Responsibility for mutual growth",
    "global_aboutus_introduction_development_title": "Our History",
    "global_aboutus_introduction_development_year_1": "2023",
    "global_aboutus_introduction_development_year_1_events_1": "Style3D AI models were introduced to enhance productivity through AI-based content generation.",
    "global_aboutus_introduction_development_year_2": "2022",
    "global_aboutus_introduction_development_year_2_events_1": "The inaugural set of national standards in digital fashion has been released, with Style3D actively contributing to their formulation.",
    "global_aboutus_introduction_development_year_2_events_2": "We co-authored the coursebook, 3D Digital Fashion Design.",
    "global_aboutus_introduction_development_year_2_events_3": "Style3D has unveiled its hardware product series, providing a streamlined and convenient solution for rapid fabric digitalization.",
    "global_aboutus_introduction_development_year_3": "2021",
    "global_aboutus_introduction_development_year_3_events_1": "The Style3D Graduate Fellowship was founded to support young researchers in the field of computer graphics.",
    "global_aboutus_introduction_development_year_3_events_2": "Style3D Research was established with the aim of assembling a world-leading graphics research team.",
    "global_aboutus_introduction_development_year_4": "2020",
    "global_aboutus_introduction_development_year_4_events_1": "During the pandemic, we offered a comprehensive suite of remote 3D solutions to support fashion enterprises, pioneering digital style promotion to mitigate the pandemic's impact. ",
    "global_aboutus_introduction_development_year_5": "2019",
    "global_aboutus_introduction_development_year_5_events_1": "Style3D products made their debut at the CHIC exhibition.",
    "global_aboutus_introduction_development_year_5_events_2": "",
    "global_aboutus_introduction_development_year_6": "2018",
    "global_aboutus_introduction_development_year_6_events_1": "Style3D Studio - The pioneering fashion 3D modeling software in China, was launched following our independent development of 3D fashion modeling, simulation, and rendering technology.",
    "global_aboutus_introduction_development_year_7": "2015",
    "global_aboutus_introduction_development_year_7_events_1": "Style3D was founded.",
    "global_aboutus_introduction_fivepoint_financing_title_1": "Shunwei Capital",
    "global_aboutus_introduction_fivepoint_financing_title_2": "Baidu Venture Capital",
    "global_aboutus_introduction_fivepoint_financing_title_3": "Vision Plus Capital",
    "global_aboutus_introduction_fivepoint_financing_title_4": "Gaorong Capital",
    "global_aboutus_introduction_fivepoint_financing_title_5": "Lenovo Capital",
    "global_aboutus_introduction_fivepoint_financing_title_6": "HILLHOUSE",
    "global_aboutus_introduction_fivepoint_title_1": "Financing",
    "global_aboutus_introduction_fivepoint_title_2": "Where We Work",
    "global_aboutus_introduction_fivepoint_title_5": "Contact us",
    "global_aboutus_introduction_fivepoint_colorcard_title": "Join Us",
    "global_aboutus_introduction_fivepoint_colorcard_title1": "Style3D Fellowship Program",
    "global_aboutus_introduction_fivepoint_concat_us_location": "Address: 3rd Floor, Building A, Sanshen International Center<br> 99 Shuanglong Street, Jiangcun Sub-district, Xihu District, Hangzhou, Zhejiang Province",
    "global_aboutus_introduction_fivepoint_concat_us_phone": "Official Hotline：400-158-0699<br>Business<a>sales{'@'}style3d.com</a><br>Media<a>PR{'@'}style3d.com</a>",
    "global_aboutus_introduction_fivepoint_office_location_title1": "Headquarter",
    "global_aboutus_introduction_fivepoint_office_location_subtitle1": "Hangzhou, China",
    "global_aboutus_introduction_fivepoint_office_location_title2": "Other offices in China：",
    "global_aboutus_introduction_fivepoint_office_location_subtitle2": "Shanghai, Guangzhou<br>Shenzhen, Xiamen<br>Qingdao, HongKong",
    "global_aboutus_introduction_fivepoint_office_location_title3": " Style3D's Global Offices：",
    "global_aboutus_introduction_fivepoint_office_location_subtitle3": "Paris, France<br>London & Manchester, UK<br>Milan, Italy<br>Brussels, Belgium<br>Munich, Germany<br>Athens, Greece<br>Ho Chi Minh City & Hanoi, Vietnam",
    "global_aboutus_introduction_fivepoint_office_location_subtitle4": "",
    "global_aboutus_responsibility_swipper_title": "Corporate Social<br>Responsibility",
    "global_aboutus_responsibility_swipper_subtitle": "",
    "global_aboutus_responsibility_swipper_info_title": "Innovating with Tech, Designing for Good",
    "global_aboutus_responsibility_swipper_info_subtitle": "",
    "global_aboutus_responsibility_greencard_title1": "Style3D is committed to \"Innovating with Tech, Designing for Good\" as its guiding principle for corporate social responsibility. Rooted in our products and technology, we strive to strike a balance between commercial success and social betterment.",
    "global_aboutus_responsibility_greencard_title2": "\"I firmly believe that the future lies in digital transformation. Our mission is to deliver essential technology, on par with industry leaders, to boost the efficiency of the textile and apparel sector. Through these core technologies, we aim to optimize the interests of businesses, individual consumers, and society, driving innovation across the entire industry.\"",
    "global_aboutus_responsibility_greencard_title3": " — Eric Liu, Founder and CEO of Style3D",
    "global_aboutus_responsibility_iconcard_info_minititle": "Our Commitment: ",
    "global_aboutus_responsibility_iconcard_info_title": "Pioneering 3D Digitalization, Enabling Sustainable Fashion",
    "global_aboutus_responsibility_iconcard_info_subtitle": "Style3D is committed to the digitalization of the fashion industry, in line with a global strategy for sustainable development. Leveraging industry-leading simulation technology, we enhance cost-efficiency while reducing the waste associated with physical garment samples.",
    "global_aboutus_responsibility_iconcardlist_title1": "Environmental Sustainability",
    "global_aboutus_responsibility_iconcardlist_subtitle1": "Style3D is at the forefront of driving digital transformation in fashion design and development, facilitating interactive engagement and presentation. Our transition from physical to digital samples significantly reduces material waste in the developmental process. Together with our clients, we are dedicated to advancing environmentally friendly and sustainable practices within the industry.",
    "global_aboutus_responsibility_iconcardlist_title2": "Industrial Sustainability",
    "global_aboutus_responsibility_iconcardlist_subtitle2": "Utilizing industry-leading technology, Style3D drives the digital transformation of the fashion value chain, resulting in enhanced development efficiency and a more sustainable fashion industry. Moreover, digital assets bolster the protection of intellectual property rights for original designs, contributing to the creation of a fair, equitable, and standardized industrial ecosystem that embodies sustainability and vitality.",
    "global_aboutus_responsibility_iconcardlist_title3": "Corporate Sustainability",
    "global_aboutus_responsibility_iconcardlist_subtitle3": "Style3D's digital design and development approach helps businesses reduce costs, enhance efficiency, alleviate inventory pressures, and adapt more effectively to market trends. Through the establishment of an agile and responsive supply chain, we drive sustainable business growth.",
    "global_aboutus_fellowship_swipper_title": "Graduate Fellowship<br>Program",
    "global_aboutus_fellowship_swipper_subtitle": "",
    "global_aboutus_fellowship_project_info_title": "Invest the Future and Continue to Grow",
    "global_aboutus_fellowship_project_info_card_content": "Style3D firmly believes that the investment in university talent today will be the key to its continuous growth and success in the future.  Starting in 2021, the Style3D graduate fellowship program funds junior graduate students who have established research interests and demonstrated potentials in computer graphics research.",
    "global_aboutus_fellowship_content_title_0": "Duration",
    "global_aboutus_fellowship_content_enroll_end_time": "Deadline:",
    "global_aboutus_fellowship_content_title_1": "Funding form",
    "global_aboutus_fellowship_content_title_2": "Eligibility",
    "global_aboutus_fellowship_content_title_3": "Application checklist",
    "global_aboutus_fellowship_content_title_4": "Selection process",
    "global_aboutus_fellowship_content_bonus_0": "A 20,000RMB gift provided to each recipient, with no strings attached; up to ten recipients in 2024.",
    "global_aboutus_fellowship_content_bonus_1": "A summer technical internship invitation to Style3D, located in Hangzhou, China or Munich，Germany.",
    "global_aboutus_fellowship_content_bonus_2": "The winner will be awarded the title of \"Style3D Fellow\".",
    "global_aboutus_fellowship_content_standards_0": "Applicants should be pursuing graduate programs at universities or institutions within China, including Hong Kong, Macau, and Taiwan.",
    "global_aboutus_fellowship_content_standards_1": "Applicants should major in Computer Science, Computer Engineering, Software Engineering, Data Science, Multimedia, or other related fields.",
    "global_aboutus_fellowship_content_standards_2": "Applicants should engage in graphics research, including but not limited to geometric modeling, rendering, animation, computer-aided design, VR/AR/MR, CAM, visualization, human-computer interaction, image/video processing, and 3D content generation.",
    "global_aboutus_fellowship_content_standards_3": "Applicants shall have no plan to graduate within the next six months.",
    "global_aboutus_fellowship_content_standards_4": "Applicants shall secure approval from their advisors.",
    "global_aboutus_fellowship_content_standards_5": "Relatives of Style3D employees cannot apply.",
    "global_aboutus_fellowship_content_document_0": "The completed application form.",
    "global_aboutus_fellowship_content_document_1": "Resume/Curriculum Vitae.",
    "global_aboutus_fellowship_content_document_2": "Research summary/proposal – up to 4 pages, including bibliography and figures.",
    "global_aboutus_fellowship_content_document_3": "One to three recommendation letters, including one from academic advisor.",
    "global_aboutus_fellowship_content_document_4": "All items must be submitted by email to fellowship@linctex.com prior to the deadline.",
    "global_aboutus_fellowship_content_procedure_0": "The assessment committee consists of three to five experts from both academia and Style3D.",
    "global_aboutus_fellowship_content_procedure_1": "The criteria emphasize the applicant's academic background, research quality, and its relevance to Style3D.",
    "global_aboutus_fellowship_content_procedure_2": "If needed, the committee may conduct interviews before reaching a final decision.",
    "global_aboutus_fellowship_content_procedure_3": "Each university or institution is generally limited to a maximum of two recipients.",
    "global_resource_center_case_swipper_title": "Customer Case",
    "global_resource_center_case_swipper_subtitle": "Customer Cases",
    "global_resource_center_case_swipper_describe": "Style3D collaborates with industry leaders to shape the future of 3D fashion.",
    "global_resource_center_case_card_title_0": "Style3D x Dishang Group",
    "global_resource_center_case_card_des_0": "Dishang has integrated the Style3D digital development platform into its Cloud Design Center, while also incorporating flexible manufacturing facilities and intelligent consumer applications…",
    "global_resource_center_case_card_title_1": "Style3D x Shilead",
    "global_resource_center_case_card_des_1": "Style3D received the 2022 Outstanding Achievement Award in Metaverse Industrialization and Innovation | ChinaInfo100.",
    "global_resource_center_case_card_title_2": "Style3D x ANTA x Baidu ",
    "global_resource_center_case_card_des_2": "We collaborate to present a virtual fashion show at SS23 China Fashion Week.",
    "global_resource_center_case_card_title_3": "Style3D x KASHION",
    "global_resource_center_case_card_des_3": "After years of collaboration, KASHION has seamlessly integrated Style3D's digital tools and established connections with multiple partners within its ecosystem.",
    "global_resource_center_news_swipper_title": "News Center",
    "global_resource_center_news_swipper_subtitle": "Newsroom",
    "global_resource_center_news_card_des_0": "Maintaining Leadership! Style3D Earns Recognition in Tencent and Huxiu Analysis Report, Listed in 36Kr Annual Enterprise Rankings.",
    "global_resource_center_news_card_des_1": "Style3D received the 2022 Outstanding Achievement Award in Metaverse Industrialization and Innovation | ChinaInfo100.",
    "global_resource_center_partners_swipper_title": "Partners",
    "global_resource_center_partners_swipper_subtitle": "",
    "global_resource_center_partners_swipper_describe": "Style3D collaborates with industry leaders to shape the future of 3D fashion.",
    "global_resource_center_partners_title_0": "Our Partners",
    "global_resource_center_partners_title_1": "Our Distributors",
    "global_resource_center_partners_des_1": "We operate in over 60 countries and regions, providing timely, efficient,<br>and professional products and services to customers worldwide.",
    "global_resource_center_partners_des_1_mobile": "We operate in over 60 countries and regions, providing timely, efficient, and professional products and services to customers worldwide.",
    "global_resource_center_apply_trial_submit_success": "Request submitted.",
    "global_send_vcode_fail": "Verification code delivery failed. Please try again later.",
    "global_policy_file_privacy": "Privacy Policy",
    "global_policy_file_cookie": "Cookie Policy",
    "global_policy_file_service": "Term of Service",
    "global_solutions_manufacturer_arrows_progressbar_title_0": "Sample Delivery",
    "global_solutions_manufacturer_arrows_progressbar_title_1": "Fitting Review",
    "global_solutions_manufacturer_arrows_progressbar_title_2": "Colorway",
    "global_solutions_manufacturer_arrows_progressbar_text_0": "Traditional cycle 7 days vs <br>3D cycle",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_0": "10 hours",
    "global_solutions_manufacturer_arrows_progressbar_text_1": "Traditional cycle 7 days vs <br>3D cycle",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_1": "4 hours",
    "global_solutions_manufacturer_arrows_progressbar_text_2": "Traditional cycle 5 days vs <br>3D cycle",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_2": "2 hours",
    "global_solutions_manufacturer_arrows_progressbar_faster": "Faster",
    "global_solutions_manufacturer_arrows_progressbar_times": "times",
    "global_solutions_brand_panoramagram_gsap_maxtext_brand": "品牌商",
    "global_solutions_brand_panoramagram_gsap_maxtext_xiaofei": "消费者",
    "global_solutions_brand_panoramagram_gsap_maxtext_maufacturertop": "成衣",
    "global_solutions_brand_panoramagram_gsap_maxtext_maufacturerbottom": "制造商",
    "global_solutions_brand_panoramagram_gsap_maxtext_fabrictop": "面料",
    "global_solutions_brand_panoramagram_gsap_maxtext_fabricbottom": "供应商",
    "global_solutions_edu_procedure_text_0": "Avatar Digitalization",
    "global_solutions_edu_procedure_text_1": "Fabric Digitalization",
    "global_solutions_edu_procedure_text_2": "3D Design",
    "global_solutions_edu_procedure_text_3": "3D Sample & Fitting",
    "global_solutions_edu_procedure_text_4": "Digital Manufacturing",
    "global_solutions_edu_procedure_text_5": "Digital Marketing",
    "global_home_header_submenu_link_long_1_0": "Brands",
    "global_home_header_submenu_link_long_1_1": "Manufacturers",
    "global_home_header_submenu_link_long_1_2": "Fabric Suppliers",
    "global_home_header_submenu_link_long_1_3": "Education",
    "global_home_header_submenu_link_long_1_4": "Virtual Fashion",
    "global_home_header_submenu_link_long_4_1": "Corporate Social Responsibility",
    "global_cloud_swipper_colorTitle_0": "Cloud",
    "global_fabric_swipper_colorTitle_1": "Fabric",
    "global_studio_swipper_colorTitle_2": "Studio",
    "global_studio_swipper_colorTitle_3": "Atelier",
    "global_studio_swipper_colorTitle_4": "Simulator",
    "global_studio_swipper_colorTitle_5": "One",
    "global_studio_swipper_colorTitle_6": "MixMatch",
    "global_solutions_brand_panoramagram_gsap_mintext_purchase": "选购采买",
    "global_solutions_brand_panoramagram_gsap_mintext_fabricsupply": "数字面料供给",
    "global_solutions_brand_panoramagram_gsap_mintext_clothsupply": "数字成衣供给",
    "global_dpc_content_services_text_0": "Showcasing garments and fabrics assets in full",
    "global_aboutus_responsibility_supplementlist_title1": "Our commitment: An inclusive, diverse and vibrant work culture",
    "global_aboutus_responsibility_supplementlist_subtitle1": "At Linctex, we cultivate a work culture that's both inclusive and diverse. It's a place <br>where employees naturally grow and make meaningful contributions to society.",
    "global_aboutus_responsibility_supplementlist_title2": "Gender Inclusivity",
    "global_aboutus_responsibility_supplementlist_subtitle2": "Currently, 40% of our talented workforce comprises women, with 30% in leadership positions. Additionally, we are proud to have a diverse team that includes members of the LGBT+ community. <br><br>At Style3D, we uphold a zero-tolerance policy against discrimination and harassment. For us, these principles are not mere checkboxes for compliance; they are foundational elements of a culture that places respect and dignity above all else. ",
    "global_aboutus_responsibility_supplementlist_title3": "Diversity, Inclusion, and Belonging",
    "global_aboutus_responsibility_supplementlist_subtitle3": "We believe that innovation thrives in a culture of inclusivity. We craft an environment where everyone, irrespective of their background, finds a space to flourish. Equal opportunity isn't a catchphrase here; it's engineered into the very fabric of our work life.",
    "global_aboutus_responsibility_supplementlist_title4": "Educating the Next Generation of Fashion Professionals",
    "global_aboutus_responsibility_supplementlist_subtitle4": "We share our knowledge and expertise with more than 100 schools and universities all over the world by providing software licenses and training to students, and serve as a platform showcasing emerging creative talent to industry professionals.",
    "global_unit_text_1": "faster",
    "global_aboutus_fellowship_detail_text_0": "Prize money:",
    "global_aboutus_fellowship_detail_text_1": "RMB",
    "global_aboutus_fellowship_detail_text_2": "awardees",
    "global_aboutus_fellowship_content_announce_time": "Announcement:",
    "global_aboutus_fellowship_history_title": "List of Past Recipients",
    "global_aboutus_fellowship_winner_list_2023_0_name": "Wu Tong",
    "global_aboutus_fellowship_winner_list_2023_0_university": "Chinese Academy of Sciences",
    "global_aboutus_fellowship_winner_list_2023_1_name": "Zhang Yuxin",
    "global_aboutus_fellowship_winner_list_2023_1_university": "Chinese Academy of Sciences",
    "global_aboutus_fellowship_winner_list_2023_2_name": "Li Mo",
    "global_aboutus_fellowship_winner_list_2023_2_university": "China Science & Technology University",
    "global_aboutus_fellowship_winner_list_2023_3_name": "Ao Tenglong",
    "global_aboutus_fellowship_winner_list_2023_3_university": "Peking University",
    "global_aboutus_fellowship_winner_list_2023_4_name": "Tang Xiangjun",
    "global_aboutus_fellowship_winner_list_2023_4_university": "Zhejiang University",
    "global_aboutus_fellowship_winner_list_2023_5_name": "Shen Yuefan",
    "global_aboutus_fellowship_winner_list_2023_5_university": "Zhejiang University",
    "global_aboutus_fellowship_winner_list_2023_6_name": "Zeng Lingzhi",
    "global_aboutus_fellowship_winner_list_2023_6_university": "Tsinghua University",
    "global_aboutus_fellowship_winner_list_2023_7_name": "Zheng Shaokun",
    "global_aboutus_fellowship_winner_list_2023_7_university": "Tsinghua University",
    "global_aboutus_fellowship_winner_list_2023_8_name": "Li Haotian",
    "global_aboutus_fellowship_winner_list_2023_8_university": "The Hong Kong University of Science and Technology",
    "global_aboutus_fellowship_winner_list_2023_9_name": "Qiu Lingteng",
    "global_aboutus_fellowship_winner_list_2023_9_university": "The Chinese University of Hong Kong, Shenzhen",
    "global_aboutus_fellowship_winner_list_2022_0_name": "Xin Hanggao",
    "global_aboutus_fellowship_winner_list_2022_0_university": "Tsinghua University",
    "global_aboutus_fellowship_winner_list_2022_1_name": "Ni Xingyu",
    "global_aboutus_fellowship_winner_list_2022_1_university": "Peking University",
    "global_aboutus_fellowship_winner_list_2022_2_name": "Lin Xutong",
    "global_aboutus_fellowship_winner_list_2022_2_university": "Peking University",
    "global_aboutus_fellowship_winner_list_2022_3_name": "Liu Fenglin",
    "global_aboutus_fellowship_winner_list_2022_3_university": "Institute of Computing Technology, Chinese Academy",
    "global_aboutus_fellowship_winner_list_2022_4_name": "Xiao Chufeng",
    "global_aboutus_fellowship_winner_list_2022_4_university": "City University of Hong Kong",
    "global_aboutus_fellowship_winner_list_2022_5_name": "Yuan Linping",
    "global_aboutus_fellowship_winner_list_2022_5_university": "The Hong Kong University of Science and Technology",
    "global_aboutus_fellowship_winner_list_2022_6_name": "Fan Jiahui",
    "global_aboutus_fellowship_winner_list_2022_6_university": "Nanjing University of Science and Technology",
    "global_aboutus_fellowship_winner_list_2022_7_name": "Huang Buzhen",
    "global_aboutus_fellowship_winner_list_2022_7_university": "Southeast University",
    "global_aboutus_fellowship_winner_list_2022_8_name": "Liu Jiafeng",
    "global_aboutus_fellowship_winner_list_2022_8_university": "Zhejiang University",
    "global_aboutus_fellowship_winner_list_2022_9_name": "Dong Junting",
    "global_aboutus_fellowship_winner_list_2022_9_university": "Zhejiang University",
    "global_aboutus_fellowship_winner_list_2021_0_name": "Huang Jiahui",
    "global_aboutus_fellowship_winner_list_2021_0_university": "Tsinghua University",
    "global_aboutus_fellowship_winner_list_2021_1_name": "Gao Duan",
    "global_aboutus_fellowship_winner_list_2021_1_university": "Tsinghua University",
    "global_aboutus_fellowship_winner_list_2021_2_name": "Su Jianping",
    "global_aboutus_fellowship_winner_list_2021_2_university": "China Science & Technology University",
    "global_aboutus_fellowship_winner_list_2021_3_name": "Yang Kaizhi",
    "global_aboutus_fellowship_winner_list_2021_3_university": "China Science & Technology University",
    "global_aboutus_fellowship_winner_list_2021_4_name": "Zhang Zihao",
    "global_aboutus_fellowship_winner_list_2021_4_university": "Institute of Computing Technology, Chinese Academy",
    "global_aboutus_fellowship_winner_list_2021_5_name": "Xu Ben",
    "global_aboutus_fellowship_winner_list_2021_5_university": "Nankai University",
    "global_aboutus_fellowship_winner_list_2021_6_name": "Zhao Hang",
    "global_aboutus_fellowship_winner_list_2021_6_university": "National University of Defense Technology",
    "global_aboutus_fellowship_winner_list_2021_7_name": "Zhu Junqiu",
    "global_aboutus_fellowship_winner_list_2021_7_university": "Shandong University",
    "global_aboutus_fellowship_winner_list_2021_8_name": "Deng Zikun",
    "global_aboutus_fellowship_winner_list_2021_8_university": "Zhejiang University",
    "global_aboutus_fellowship_winner_list_2021_9_name": "Peng Sida",
    "global_aboutus_fellowship_winner_list_2021_9_university": "Zhejiang University"
}
