// 在Nuxt3中使用lenis Smooth Scroll
// https://github.com/studio-freight/lenis/issues/17#issuecomment-1432730270
import Lenis from '@studio-freight/lenis';

const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    orientation: 'vertical', // vertical, horizontal
    gestureOrientation: 'vertical', // vertical, horizontal, both
    smoothWheel: true,
    wheelMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
});

// //get scroll value
// lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
//     console.log({ scroll, limit, velocity, direction, progress });
// });

const raf = (time) => {
    lenis.raf(time);
    requestAnimationFrame(raf);
};

requestAnimationFrame(raf);

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('lenis', lenis);

    // scroll to top on route change
    nuxtApp.$router.afterEach((to, from) => {
        lenis.stop();
        if (to.path !== from.path) {
            lenis.scrollTo('.app', {
                // your container class or id
                offset: 0,
                duration: 0,
                easing: () => {},
                immediate: true,
            });
        }
        lenis.start();
    });
});
