<template>
    <Transition name="slide-fade">
        <div v-if="visible" class="linctex-cookie-modal">
            <div class="cookie-modal-body">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path
                            d="M17.6995 34.5001C13.4212 34.4419 9.33436 32.7164 6.3088 29.6909C3.28324 26.6653 1.55777 22.5785 1.49955 18.3001C1.34955 9.60011 8.02455 2.25011 16.7245 1.57511H17.1745C18.9745 1.50011 20.6245 2.10011 21.8996 3.30011C23.2495 4.57511 23.9995 6.37511 23.9995 8.25011C23.9995 8.47511 23.9995 8.70011 24.0746 8.92511C24.2995 10.4251 25.5746 11.7001 27.0746 11.9251C27.2996 12.0001 27.5245 12.0001 27.7495 12.0001C29.6245 12.0001 31.4246 12.7501 32.6996 14.1001C33.8996 15.3751 34.5746 17.0251 34.4245 18.8251V19.2751C33.8246 27.8251 26.6245 34.5001 17.9995 34.5001H17.6995ZM16.9495 4.57511C9.97455 5.10011 4.34955 11.2501 4.49955 18.2251C4.64955 25.5001 10.4245 31.3501 17.7745 31.5001C24.8245 31.5751 30.8995 26.0251 31.4995 19.0501V18.6751C31.5746 17.7751 31.1996 16.8751 30.5996 16.2001C29.9246 15.4501 28.8745 15.0751 27.8246 15.0751C27.4496 15.0751 26.9995 15.0751 26.6245 15.0001C23.8496 14.5501 21.5995 12.3001 21.1495 9.52511C20.9995 9.00011 20.9995 8.62511 20.9995 8.25011C20.9995 7.20011 20.5495 6.15011 19.8745 5.47511C19.1995 4.87511 18.3745 4.50011 17.5495 4.50011H17.3995C17.2495 4.50011 17.0995 4.50011 16.9495 4.57511ZM14.9995 14.2501C13.3495 14.2501 11.9995 12.9001 11.9995 11.2501C11.9995 9.60011 13.3495 8.25011 14.9995 8.25011C16.6495 8.25011 17.9995 9.60011 17.9995 11.2501C17.9995 12.9001 16.6495 14.2501 14.9995 14.2501ZM14.2495 22.5001C15.5245 22.5001 16.4995 23.4751 16.4995 24.7501C16.4995 26.0251 15.5245 27.0001 14.2495 27.0001C12.9745 27.0001 11.9995 26.0251 11.9995 24.7501C11.9995 23.4751 12.9745 22.5001 14.2495 22.5001ZM10.4995 20.6251C9.22455 20.6251 8.24955 19.6501 8.24955 18.3751C8.24955 17.1001 9.22455 16.1251 10.4995 16.1251C11.7745 16.1251 12.7495 17.1001 12.7495 18.3751C12.7495 19.6501 11.7745 20.6251 10.4995 20.6251Z"
                            fill="#0032FF"
                        />
                    </svg>
                </div>
                <span v-if="isChinese" class="zh">
                    我们使用Cookie来确保您的浏览体验，请访问我们的
                    <a @click="openPolicyFile('cookie')">Cookie政策</a>了解更多信息。点击接受表示您同意使用Cookie。
                </span>
                <span v-else class="en">
                    Cookies help us deliver a better website to you. By clicking 'Accept' or continuing to browse our
                    site, you agree to the use of cookies as described in our
                    <a @click="openPolicyFile('cookie')">Cookie Policy</a>.
                </span>
            </div>
            <div class="cookie-modal-footer">
                <CommonComponentsButtonLine
                    size="medium"
                    :animate="false"
                    :button-text="t('global_button_text_decline')"
                    @click="handleCancel"
                />
                <CommonComponentsButtonLine
                    size="medium"
                    :animate="false"
                    background
                    light
                    :button-text="t('global_button_text_accept')"
                    @click="handleConfirm"
                />
            </div>
        </div>
    </Transition>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import { openPolicyFile } from '~~/composables/usePolicyFile.ts';
    import i18n from '@/locales';

    const { t } = i18n.global;
    const visible = ref(false);
    const result = ref();
    const isChinese = ifCNLocale();

    /**
     * 改变组件显隐
     * @param value true(显示) / false(隐藏)
     */
    const setVisible = (value: boolean) => {
        visible.value = value;
    };

    /**
     * 确认
     */
    const handleConfirm = () => {
        visible.value = false;
        result.value = 'confirm';
    };

    /**
     * 取消
     */
    const handleCancel = () => {
        visible.value = false;
        result.value = 'cancel';
    };

    defineExpose({
        visible,
        result,
        setVisible,
    });
</script>

<style lang="less" scoped>
    .linctex-cookie-modal {
        position: fixed;
        width: 500px;
        padding: 24px 36px;
        backdrop-filter: blur(10px);
        border-radius: 10px;
        background: #fff;
        z-index: 10000;
        bottom: 13px;
        right: 11px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0 0 0 1px #00000029;

        .cookie-modal-body {
            display: flex;
            gap: .convert(29px) [ @vw];
            a {
                color: #000;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
        .cookie-modal-footer {
            width: 100%;
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }

        @media (max-width: @screen-mobile-width) {
            position: fixed;
            width: 100%;
            padding: 24px 36px;
            backdrop-filter: blur(10px);
            border-radius: 0px;
            background: #fff;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // box-shadow: 0 0 0 1px #00000029;

            .cookie-modal-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;
                & span {
                    text-align: center;
                }
            }
            .cookie-modal-footer {
                width: 100%;
                margin-top: 32px;
                display: flex;
                justify-content: center;
                gap: 16px;
            }
        }
    }
    .slide-fade {
        &-enter-active {
            transition: all 0.8s ease;
            transition-delay: 2s;
        }
        &-leave-active {
            transition: all 0.8s ease;
        }

        &,
        &-enter-from,
        &-leave-to {
            transform: translateX(30px);
            opacity: 0;
            @media (max-width: @screen-mobile-width) {
                transform: translateY(50%);
                opacity: 0;
            }
        }
    }
</style>
