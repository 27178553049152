import { createApp, watch } from 'vue';
import CookieDialog from './index.vue';

/**
 *
 * @param config 组件配置
 * @returns Promise
 */
export const showCookieDialog = (config) => {
    // 创建组件实例
    const dialogApp = createApp(CookieDialog, config);

    return new Promise((resolve, reject) => {
        mountDialog(dialogApp, { resolve, reject });
    });
};

/**
 * 展示组件
 * @param app 组件实例
 * @param param1
 */
const mountDialog = (app, { resolve, reject }) => {
    // 创建文档碎片
    const dFrag = document.createDocumentFragment();
    // 将组件挂载在文档碎片上
    const vm = app.mount(dFrag);

    vm.setVisible(true);
    document.body.appendChild(dFrag);

    watch(
        () => vm.visible,
        (visible) => {
            if (!visible) {
                switch (vm.result) {
                    case 'confirm':
                        console.log('dialog confirmed');
                        resolve(true);
                        break;
                    case 'cancel':
                        console.log('dialog canceled');
                        reject(false);
                        break;
                }
                destroyDialog(app);
            }
        },
    );
};

/**
 * 卸载组件
 * @param app 组件实例
 */
const destroyDialog = (app) => {
    app.unmount();
};
