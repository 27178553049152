import { createApp } from 'vue';
import FloatingButton from './index.vue';

export const initFloatingButton = (config) => {
    // 创建组件实例
    const app = createApp(FloatingButton, config);

    mountFloatingButton(app);
};

const mountFloatingButton = (app) => {
    // 创建文档碎片
    const dFrag = document.createDocumentFragment();
    // 将组件挂载在文档碎片上
    const vm = app.mount(dFrag);

    // vm.setVisible(true);
    document.body.appendChild(dFrag);
};

const destroyFloatingButton = (app) => {
    app.unmount();
};
