<template>
    <div class="svg-icon">
        <svg class="svg" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M9 0.514667L17.4853 8.99995L9 17.4852" stroke-linecap="round" />
                <path d="M17.3998 8.94692L1.74039 9.04996" stroke-linecap="round" />
            </g>
        </svg>
    </div>
</template>
<style scoped>
    .svg-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
