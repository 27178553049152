import { ScrollTrigger } from 'gsap/all';

export default defineNuxtRouteMiddleware((to, from) => {
    console.log('RouterChanged,kill ScrollTrigger  of', ScrollTrigger.getAll().length);
    ScrollTrigger.killAll();
    // if (to.params.id === '1') {
    //     return abortNavigation()
    // }
    // return navigateTo(to)
});
