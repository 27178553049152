// 初始化Google Analytics
export const initGA = (locale: string) => {
    // console.log(`init GA with '${locale}'`);
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-T1P1RK73F7';

    document.head.appendChild(scriptTag);

    const scriptTag2 = document.createElement('script');

    // 将 gtag.js 代码设置为 script 标签的 innerHTML
    scriptTag2.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-T1P1RK73F7');
  gtag('set', {'user_language': '${locale}' });
`;

    document.head.appendChild(scriptTag2);
};
