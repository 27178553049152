/**
 * @description 判断参数类型
 * @param {any} param 参数
 */
const type = (param: any) => {
    return Object.prototype.toString.call(param).slice(8, -1).toLowerCase();
};

/**
 * @description 判断是不是对象类型
 * @param {Object} object 对象入参
 * @param {boolean} real 是否是真正的对象
 */
const isObject = (object: any, real: boolean) => {
    if (real) {
        return type(object) === 'object';
    } else {
        return object && typeof object === 'object';
    }
};

/**
 * @description 合并两个对象，但不会覆盖已存在的属性
 * @param {Object} a
 * @param {Object} b
 */
const merge = (a: Record<string, any>, b: Record<string, any>) => {
    for (const key in b) {
        // eslint-disable-next-line no-prototype-builtins
        if (!a.hasOwnProperty(key)) {
            a[key] = b[key];
        } else if (isObject(b[key], true) && isObject(a[key], true)) {
            merge(a[key], b[key]);
        }
    }
    return a;
};

const isPromise = (p: any): boolean => {
    return p && p.then && p.catch;
};

/**
 * @description 如果请求/相应拦截器被 lock 了，后面的请求/相应将进入队列等待，否则，它将直接执行
 * @param {Promise} promise 如果 promise 存在，则意味着拦截器被 lock 了
 * @param {Function} callback
 */
function enqueueIfLocked(promise: Promise<any> | undefined | null, callback: () => void) {
    if (promise) {
        promise.then(() => {
            callback();
        });
    } else {
        callback();
    }
}

export const utils = {
    isPromise,
    merge,
    enqueueIfLocked,
};
