const httpReg = /^http(s)?:(.*?)(\.)?/;

/**
 * 检测数据类型
 * @param obj
 * @returns string
 */
export function typeOf(obj: any) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object',
    };
    return map[toString.call(obj)];
}

/**
 * https://www.style3d.com?code=123456
 * 从上面链接中取出code 的值并返回，如果不存在返回空字符串
 * @param {*} url 比如 https://www.style3d.com
 * @param {*} param 比如 code
 */
export const getUrlParam = (url: string, param: string) => {
    const searchIndex = url.indexOf('?');
    const searchParams = url.slice(searchIndex + 1).split('&');
    for (let i = 0; i < searchParams.length; i++) {
        const items = searchParams[i].split('=');
        if (items[0].trim() === param) {
            return decodeURIComponent(items[1].trim());
        }
    }
    return '';
};

/**
 * 返回url 中所有的参数
 * @param {*} key
 */
export const getUrlParams = (key: string, url = '') => {
    const params: Record<string, any> = {};
    const reg = /([\\?|&])(.+?)=([^&?]*)/gi;
    let arr = reg.exec(url);

    while (arr) {
        params[arr[2]] = decodeURIComponent(arr[3]);

        arr = reg.exec(url);
    }

    if (key) return params[key];
    return params;
};

/**
 * 链接添加参数
 */
export const addUrlParams = (params: Record<string, any> = {}, url = '') => {
    const urlParams = {
        ...getUrlParams('', url),
        ...params,
    };
    const search = Object.keys(urlParams)
        .filter((key) => key && urlParams[key] !== undefined)
        .map((key) => `${key}=${urlParams[key]}`)
        .join('&');
    const index = url.indexOf('?');
    return `${index > -1 ? url.substr(0, index) : url}?${search}`;
};

/**
 * stackoverflow: https://stackoverflow.com/questions/6997262/how-to-pull-url-file-extension-out-of-url-string-using-javascript
 * 根据URL获取资源文件的类型
 * @param {string} url
 * @return {string}
 */
export function getFileExtensionByUrl(url = ''): string {
    return (url.split(/[#?]/)[0].split('.').pop() || '')?.trim();
}

export function getFileNameWithExt(filePath = ''): string {
    return filePath.split(/[#?]/)[0].split('/').pop() || '';
}

export function getFileName(filePath = ''): string {
    return filePath.split(/[#?]/)[0].split('/').pop()?.split('.')[0]?.trim() || '';
}

/**
 * 是否图片
 * @param {string} src
 * @returns {boolean}
 */
export function isImage(src: string): boolean {
    const ext = getFileExtensionByUrl(src);
    return ['png', 'jpg', 'jpeg', 'gif'].includes(ext.toLowerCase());
}

/**
 * 是否PDF
 * @param {string} src
 * @returns {boolean}
 */
export function isPDF(src: string): boolean {
    const ext = getFileExtensionByUrl(src);
    return ['pdf'].includes(ext.toLowerCase());
}

/**
 * 是否视频
 * @param {string} src
 * @returns {boolean}
 */
export function isVideo(src: string): boolean {
    const ext = getFileExtensionByUrl(src);
    return ['mp4', 'webm', 'avi', 'rmvb'].includes(ext.toLowerCase());
}

export const loopTranslate = (obj: Record<string, any> | [], func: (key: string) => string) => {
    if (obj instanceof Array) {
        obj.forEach((i) => loopTranslate(i, func));
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach((key) => {
            const newKey = func(key);
            if (newKey !== key) {
                obj[newKey] = obj[key];
                delete obj[key];
            }
            loopTranslate(obj[newKey], func);
        });
    }
};

export const underline2Hump = (str: string) => {
    return str.replace(/(\w)_(\w)/g, ($1, $2, $3) => {
        return $2 + $3.toUpperCase();
    });
};

export const hump2Underline = (str: string) => {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

/**
 * URL encode 并不会对特殊字符处理， 这里做兼容处理
 */
export const parseURL = (url: string) => {
    const uri = new URL(url);
    let pathname = url.split(uri.origin)[1];
    pathname = pathname.split('?')[0];
    pathname = pathname
        .split('/')
        .map((path) => encodeURIComponent(path))
        .join('/');
    return {
        host: uri.host,
        pathname,
        href: uri.origin + pathname + uri.search,
        hostname: uri.hostname,
        origin: uri.origin,
        protocol: uri.protocol,
        search: uri.search,
        port: uri.port,
    };
};

export const dataURLtoBlob = (dataurl: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export const isObjectValueEqual = (origin: any, target: any) => {
    if (typeof target !== 'object' || target === null) {
        return origin === target;
    }
    if (Array.isArray(origin) || Array.isArray(target)) {
        if (origin.length !== target.length) {
            return false;
        } else {
            target.forEach((item: any) => {
                for (const key of Object.keys(item)) {
                    if (!isObjectValueEqual(origin?.[key], target?.[key])) {
                        return false;
                    }
                }
            });
        }
    }
    for (const key of Object.keys(target)) {
        if (!isObjectValueEqual(origin?.[key], target?.[key])) {
            return false;
        }
    }
    return true;
};

// key相同的数组项合并为一项，value值以，链接
export const mergeArrByKey = (arr: Record<string, unknown>[] = [], key: string) => {
    const tempArr = [];
    const newArr: any[] = [];
    arr.forEach((item) => {
        const index = newArr?.findIndex((i) => i.key === item.key);
        if (index > -1) {
            newArr[index].value += ',' + item.value;
        } else {
            newArr.push(item);
        }
    });
    return newArr;
};

/**
 * 生成 query 对象列表
 * @returns {}
 */
export function getQueryObject(url?: string): Record<string, string> {
    url = url || (process.client ? window.location.href : '');
    const search = url.substring(url.lastIndexOf('?') + 1);
    const obj: Record<string, string> = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1);
        let val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
    });
    return obj;
}
/**
 * 下载
 * @param url
 * @returns
 */
export function toDownload(url: string) {
    return new Promise((resolve) => {
        try {
            const link = document.createElement('a');
            link.setAttribute('download', '');
            link.href = url;
            link.click();

            const timer = setTimeout(() => {
                resolve(true);
                clearTimeout(timer);
            }, 500);
        } catch (error) {
            resolve(error);
        }
    });
}

/**
 * is JSON
 * @param str
 * @returns
 */
export const isJSON = (str: string) => {
    if (typeof str === 'string') {
        try {
            const obj = JSON.parse(str);
            if (typeof obj === 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
};

/**
 * @description 判断是否为空
 * @param value
 */
export const isEmpty = (value: any) => {
    if (value === undefined || value === null || (typeof value === 'number' && isNaN(value))) {
        return true;
    }
    if (typeof value === 'string' && value.length === 0) {
        return true;
    } else {
        return false;
    }
};
