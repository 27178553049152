import { Xhr } from '@pkgs/fetch';

export default defineNuxtPlugin(() => {
    /*
     注意：这里的 @ts-ignore 是必须要添加的，因为 nuxt 内部没有暴露 onmyfetch 内部特有入参的 TS 声明
     另外需要注意的是，这里仅使用 useFetch 即可，因为 nuxt 内部其他请求相关 api 都是基于 useFetch 进行封装
   */
    // @ts-ignore 这里的 @ts-ignore 是必须要添加的，具体原因见上文
    const xhr = Xhr($fetch);

    // 全局请求配置
    xhr.config.baseURL = 'https://plausible.io';

    // 全局请求拦截
    xhr.interceptors.request.use((request) => {
        // console.log('plugin 请求拦截', request);
        return request;
    });

    // 全局响应拦截
    xhr.interceptors.response.use((response) => {
        // console.log('plugin 响应拦截', response);
        return response;
    });

    return {
        provide: {
            xhr,
        },
    };
});
