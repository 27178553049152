import { omit } from 'lodash-es';
import { utils } from './utils';
import { CUSTOM_OPTIONS_KEYS, XhrMethod } from './utils/constant';

import type {
    XhrRequestInterceptor,
    XhrResponseInterceptor,
    OnSuccess,
    OnError,
    OnSend,
    XhrOptions,
    NuxtFetchOptions,
    XhrResponse,
    XhrError,
} from './types';

export interface RequestInterceptor extends XhrRequestInterceptor {
    p?: Promise<any> | null;
    handler: OnSend | undefined;
}

export interface ResponseInterceptor extends XhrResponseInterceptor {
    p?: Promise<any> | null;
    handler: OnSuccess | undefined;
    onerror: OnError | undefined;
}

export interface Interceptors {
    request: RequestInterceptor;
    response: ResponseInterceptor;
}

export type WrapInterceptor =
    | Omit<RequestInterceptor, 'lock' | 'unlock' | 'clear'>
    | Omit<ResponseInterceptor, 'lock' | 'unlock' | 'clear'>;

export type Engine = (url: string, options: NuxtFetchOptions) => Promise<XhrResponse>;

class XHR {
    // 拦截器
    readonly interceptors: Interceptors;
    // 全局配置
    config: XhrOptions;
    /*
   请求引擎，后续可根据环境的不同，传入不同的请求引擎，进行不同端的适配（如小程序、CSR等）
   目前仅支持 nuxt3 内置请求 api useFetch
   */
    readonly engine: Engine;

    constructor(engine: Engine) {
        this.engine = engine;

        const wrap = (interceptor: WrapInterceptor) => {
            let resolve: ((value?: any) => void) | null;
            let reject: ((value?: any) => void) | null;

            const _clear = () => {
                interceptor.p = resolve = reject = null;
            };

            utils.merge(interceptor, {
                lock() {
                    if (!resolve) {
                        interceptor.p = new Promise((_resolve, _reject) => {
                            resolve = _resolve;
                            reject = _reject;
                        });
                    }
                },
                unlock() {
                    if (resolve) {
                        resolve();
                        _clear();
                    }
                },
                clear() {
                    if (reject) {
                        reject('cancel');
                        _clear();
                    }
                },
            });
        };

        // 拦截器，一旦请求或者是响应的拦截器被 lock 了，剩余的请求或响应将会被放入等待队列中，直到该拦截器被 unlock
        const interceptors = (this.interceptors = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response: {
                handler: undefined,
                onerror: undefined,

                // 响应拦截器
                use(handler, onerror) {
                    this.handler = handler;
                    this.onerror = onerror;
                },
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            request: {
                handler: undefined,
                // 请求拦截器
                use(handler) {
                    this.handler = handler;
                },
            },
        });

        const irRequest = interceptors.request;
        const irResponse = interceptors.response;
        wrap(irRequest);
        wrap(irResponse);

        // 全局配置
        this.config = {
            timeout: 0,
            displayError: false,
            key: '',
        };
    }

    request(url: string, options?: XhrOptions) {
        const interceptors = this.interceptors;
        const requestInterceptor = interceptors.request;
        const responseInterceptor = interceptors.response;
        const requestInterceptorHandler = requestInterceptor.handler;
        // request 默认请求方法为 get
        options = { method: XhrMethod.get, ...options };
        const engine = this.engine;

        const promise = new Promise((resolve, reject) => {
            /**
             * @description 发起请求
             * @param {XhrOptions} options options入参
             */
            function makeRequest(options: XhrOptions) {
                // 提取 nuxt 请求独有的请求配置传入 useFetch
                const nuxtOptions = omit(options, CUSTOM_OPTIONS_KEYS) as NuxtFetchOptions;
                engine(url, { ...nuxtOptions })
                    .then((res: XhrResponse) => {
                        // TODO 模拟 status，后续将根据后端接口约定进行特殊处理
                        const status = 304;
                        if ((status >= 200 && status < 300) || status === 304) {
                            onresult(responseInterceptor.handler, res, 0);
                        } else {
                            const err: XhrError = { msg: '出错了~', status, response: res };
                            onerror(err);
                        }
                    })
                    .catch((err: XhrError) => {
                        onerror({ msg: err.msg, status: 500 });
                    });

                /**
                 * @description 请求结果处理函数
                 * @param handler
                 * @param data
                 * @param {number} type true 代表请求失败 false 代表请求成功
                 */
                function onresult(
                    handler: OnSuccess,
                    data: XhrError | XhrResponse | Promise<XhrResponse | XhrError | void>,
                    type: number,
                ) {
                    // 响应拦截
                    utils.enqueueIfLocked(responseInterceptor.p, function () {
                        if (handler) {
                            // 如果失败，添加请求信息
                            if (type) (data as XhrError).request = options;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            const result = handler.call(responseInterceptor, data, Promise);
                            data = result === undefined ? data : result;
                        }
                        if (!utils.isPromise(data)) {
                            data = Promise[type === 0 ? 'resolve' : 'reject'](data);
                        }
                        (data as Promise<void | XhrError | XhrResponse>)
                            .then((d: any) => {
                                resolve(d);
                            })
                            .catch((e: Error) => {
                                reject(e);
                            });
                    });
                }

                /**
                 * @description 错误处理函数
                 * @param {XhrError} e 错误信息
                 */
                function onerror(err: XhrError) {
                    onresult(responseInterceptor.onerror, err, -1);
                }
            }

            utils.enqueueIfLocked(requestInterceptor.p, () => {
                // 合并配置，接口配置高于全局配置
                const mergedOptions: XhrOptions | Promise<XhrOptions> = {
                    ...options,
                    ...this.config,
                };
                let result = mergedOptions;
                // 如果请求拦截器存在，则调用请求拦截器进行处理
                if (requestInterceptorHandler) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    result = requestInterceptorHandler.call(requestInterceptor, result, Promise) || result;
                }
                // result promise 化
                if (!utils.isPromise(result)) result = Promise.resolve(result);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result.then(
                    (d: any) => {
                        // if options continue
                        if (d === mergedOptions) {
                            makeRequest(d);
                        } else {
                            resolve(d);
                        }
                    },
                    (err: Error) => {
                        reject(err);
                    },
                );
            });
        });

        return promise;
    }

    /**
     * @description get 语法糖
     */
    get(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.get }, options ?? {}));
    }

    /**
     * @description post 语法糖
     */
    post(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.post }, options ?? {}));
    }

    /**
     * @description put 语法糖
     */
    put(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.put }, options ?? {}));
    }

    /**
     * @description patch 语法糖
     */
    patch(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.patch }, options ?? {}));
    }

    /**
     * @description patch 语法糖
     */
    delete(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.delete }, options ?? {}));
    }

    /**
     * @description head 语法糖
     */
    head(url: string, options?: XhrOptions) {
        return this.request(url, utils.merge({ method: XhrMethod.head }, options ?? {}));
    }

    lock() {
        this.interceptors.request.lock();
    }

    unlock() {
        this.interceptors.request.unlock();
    }

    clear() {
        this.interceptors.request.clear();
    }

    /**
     * @description 并发请求处理，发起多个并发请求，参数是一个promise 数组；当所有请求都成功后才会调用then，只要有一个失败，就会调 catch。
     */
    all(promises: Promise<any>[]) {
        return Promise.all(promises);
    }
}

export const Xhr = (engine: Engine) => new XHR(engine);
