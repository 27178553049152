import { showCookieDialog } from '@/components/Dialog/Cookie';

export const requestCookiePermission = async () => {
    console.log('requestCookiePermission', checkCookie());
    if (checkCookie()) {
        return;
    }
    // 向用户请求Cookie使用权限
    const permission: boolean = await showCookieDialog();
    if (permission) {
        localStorage.setItem('cookie-permission', 'granted');
    } else {
        localStorage.setItem('cookie-permission', 'denied');
    }
    return permission;
};

export const checkCookie = () => {
    // console.log('checkCookie');
    return localStorage.getItem('cookie-permission') === 'granted';
};
