<template>
    <button
        ref="buttonNode"
        class="button-container"
        :class="[
            {
                disabled: disabled,
                borderless: borderless,
                light: light,
                transparent: transparent,
                background: background,
                gohst: gohst,
            },
            size,
        ]"
        :disabled="disabled"
        @mouseenter="onEnter"
        @mouseleave="onLeave"
    >
        <!-- hover 绝对定位遮罩 -->
        <div ref="mask" class="button-hover-mask">
            <div class="button-text">
                {{ buttonText }}
            </div>
            <div class="button-arrow">
                <CommonComponentsSVGCommonArrow />
            </div>
        </div>
        <!-- normal -->
        <div ref="container" class="button-flex-container">
            <div id="button-text" class="button-text">
                {{ buttonText }}
            </div>
            <!-- <div class="button-arrow">
                <CommonComponentsSVGCommonArrow />
            </div> -->
        </div>
    </button>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { matchMobile } from '~~/composables/useMatchRules.ts';

    gsap.registerPlugin(ScrollTrigger);
    const props = withDefaults(
        defineProps<{
            buttonText: string;
            disabled?: boolean;
            borderless?: boolean;
            light?: boolean;
            transparent?: boolean;
            size?: 'normal' | 'small' | 'medium';
            animate?: boolean;
            background?: boolean;
            gohst?: boolean;
        }>(),
        {
            disabled: false,
            borderless: false,
            light: false,
            size: 'normal',
            animate: true,
            background: false,
            gohst: false,
        },
    );

    let tl: gsap.core.Timeline;
    const buttonNode = ref(null);
    const container = ref(null);
    const mask = ref(null);
    const onEnter = () => {
        if (!props.disabled && props.animate && !matchMobile()) {
            tl = gsap
                .timeline({ ease: 'none', delay: 0 })
                .fromTo(
                    [container.value, mask.value],
                    { xPercent: 0 },
                    {
                        xPercent: 99,
                        duration: 0.3,
                        delay: 0.2,
                    },
                )
                .set(buttonNode.value, {
                    'box-shadow': 'none',
                    background: 'transparent',
                });
        }
    };
    const onLeave = () => {
        if (tl) tl.reverse();
    };
</script>

<style lang="less" scoped>
    .button-container {
        background-color: #ffffff;
        color: #000000;

        padding: 0;
        position: relative;
        height: fit-content;
        width: fit-content;
        min-width: 94px;
        min-height: 34px;

        box-shadow: inset 0 0 0 1px #000;
        box-sizing: border-box;
        border: none;
        border-radius: .convert(83px) [ @vw];

        font-size: .convert(@font-size-md) [ @vw];

        line-height: 100%;
        letter-spacing: 2.24px;

        overflow: hidden;
        .button-hover-mask {
            position: absolute;
            width: 102%;
            height: 100%;
            color: #ffffff;
            border-radius: .convert(83px) [ @vw];
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .convert(@flex-gap-xs) [ @vw];

            background: #0032ff;

            top: 0;
            left: 0;
            transform: translate(-100%, 0);

            .button-arrow :deep(.svg-icon) .svg {
                width: .convert(18px) [ @vw];
                stroke: white;
            }
        }
        .button-flex-container {
            width: 100%;
            height: 100%;
            padding: .convert(16px) [ @vw] .convert(36px) [ @vw];

            display: flex;
            align-items: center;
            justify-content: center;

            .button-text {
                white-space: nowrap;
            }
        }

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            cursor: not-allowed;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.6) !important;
            .button-flex-container {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        &.borderless {
            box-shadow: none;
        }

        &.light {
            color: #ffffff;
            box-shadow: inset 0 0 0 1px #ffffff;
        }

        &.transparent {
            background-color: transparent;
        }

        &.gohst {
            color: #0032ff;
            box-shadow: inset 0 0 0 1px #0032ff;
        }

        &.background {
            background-color: #000;
            box-shadow: inset 0 0 0 1px #000;
        }

        @media (max-width: @screen-mobile-width) {
            // height: .convert(36px) [ @vw-mobile];
            height: min(.convert(36px) [ @vw-mobile], 54px);
            font-size: .convert(@font-size-xm) [ @vw-mobile]; //14px
            .button-hover-mask {
                .button-arrow :deep(.svg-icon) .svg {
                    width: .convert(12px) [ @vw-mobile];
                }
            }
        }
    }

    .button-container {
        &.small {
            height: 28px;
            font-size: 12px;
            letter-spacing: 1.92px;

            border-radius: 100px;

            .button-flex-container {
                padding: 7px .convert(12px) [ @vw];
            }

            .button-hover-mask {
                gap: 2px;
                :deep(.button-arrow) .svg-icon .svg {
                    width: .convert(14px) [ @vw];
                    stroke: white;

                    @media (max-width: @screen-mobile-width) {
                        display: none;
                    }
                }
            }
        }
        &.medium {
            height: 40px;
            font-size: 14px;
            letter-spacing: 2.24px;
            border-radius: 100px;
            min-width: 79px;

            .button-flex-container {
                padding: 0 .convert(24px) [ @vw];
            }
        }
    }

    :lang(en) {
        .button-container {
            letter-spacing: 0px !important;

            .button-flex-container {
                padding: .convert(16px) [ @vw] .convert(36px) [ @vw];
            }
        }
    }
</style>
