import { createI18n } from 'vue-i18n';
import enLocale from './en.json';
import zhLocale from './zh-CN.json';

const messages = {
    en: enLocale,
    'zh-CN': zhLocale,
};

const i18n = createI18n({
    // fix:  Not available in legacy mode useI18n
    legacy: false,
    locale: 'zh-CN',
    fallbackLocale: 'zh-CN',
    globalInjection: true, // 为每个组件注入全局属性和函数
    messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

export async function setI18nLanguage(locale: any) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
    } else {
        (<any>i18n.global.locale).value = locale;
    }
}

export const setDefaultLang = (defaultLang: any) => {
    const useLang = defaultLang;
    setI18nLanguage(useLang);
    return useLang;
};

export default i18n;
