// 自定义请求入参
export const CUSTOM_OPTIONS_KEYS = ['timeout', 'displayError'];

export enum XhrMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
    head = 'head',
}
