import { default as FellowshipDk01oQfkc4Meta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Fellowship.vue?macro=true";
import { default as IntroductionGae6I8awXdMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Introduction.vue?macro=true";
import { default as PartnersgcRIrrajAEMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Partners.vue?macro=true";
import { default as Responsibility4S6Lkdd8ruMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Responsibility.vue?macro=true";
import { default as indexa8btVTAOETMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/OSS/index.vue?macro=true";
import { default as AtelierNTsgN231zAMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Atelier.vue?macro=true";
import { default as AtelierSimulatoriACD6h907GMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/AtelierSimulator.vue?macro=true";
import { default as CloudIGVKW1hFTzMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Cloud.vue?macro=true";
import { default as FabricRxbAg5numFMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Fabric.vue?macro=true";
import { default as MarketRoXPGIwlkJMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Market.vue?macro=true";
import { default as MixMatchvmHIob0nSfMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/MixMatch.vue?macro=true";
import { default as One43AR6F8MWFMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/One.vue?macro=true";
import { default as SimulatorcI9LJL02EGMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Simulator.vue?macro=true";
import { default as StudiomnN8p2KUpnMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Studio.vue?macro=true";
import { default as CasecwnHhPWJeMMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Case.vue?macro=true";
import { default as LearningmkAmzKI1wcMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Learning.vue?macro=true";
import { default as NewsVIqsg74XsHMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/News.vue?macro=true";
import { default as OlympicmMb37kFmLAMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Olympic.vue?macro=true";
import { default as AcademyJBpVVl7WXaMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Academy.vue?macro=true";
import { default as AuxiliaryngAu4oRrLoMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Auxiliary.vue?macro=true";
import { default as Brandl5FZfdMMeAMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Brand.vue?macro=true";
import { default as FabricPZq7BQgAmVMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Fabric.vue?macro=true";
import { default as ManufacturerBrN7WY6hmfMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Manufacturer.vue?macro=true";
import { default as VirtualFashionbr4FIh9QJ5Meta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/VirtualFashion.vue?macro=true";
import { default as ConsultationServiceSO46vlL0qJMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/ConsultationService.vue?macro=true";
import { default as ContentServicestwSxS6WgDhMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/ContentServices.vue?macro=true";
import { default as CustomerSuccessKQQyrm4DmcMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/CustomerSuccess.vue?macro=true";
import { default as Help9x5kVx3m3kMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/Help.vue?macro=true";
import { default as indexDbdEeEC4GxMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Thanks/index.vue?macro=true";
import { default as indexr7RfYBSCz2Meta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/index.vue?macro=true";
import { default as indexpUVLqcdsGIMeta } from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/page1/index.vue?macro=true";
export default [
  {
    name: FellowshipDk01oQfkc4Meta?.name ?? "AboutUs-Fellowship",
    path: FellowshipDk01oQfkc4Meta?.path ?? "/AboutUs/Fellowship",
    url: "/AboutUs/Fellowship",
    meta: FellowshipDk01oQfkc4Meta || {},
    alias: FellowshipDk01oQfkc4Meta?.alias || [],
    redirect: FellowshipDk01oQfkc4Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Fellowship.vue").then(m => m.default || m)
  },
  {
    name: IntroductionGae6I8awXdMeta?.name ?? "AboutUs-Introduction",
    path: IntroductionGae6I8awXdMeta?.path ?? "/AboutUs/Introduction",
    url: "/AboutUs/Introduction",
    meta: IntroductionGae6I8awXdMeta || {},
    alias: IntroductionGae6I8awXdMeta?.alias || [],
    redirect: IntroductionGae6I8awXdMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Introduction.vue").then(m => m.default || m)
  },
  {
    name: PartnersgcRIrrajAEMeta?.name ?? "AboutUs-Partners",
    path: PartnersgcRIrrajAEMeta?.path ?? "/AboutUs/Partners",
    url: "/AboutUs/Partners",
    meta: PartnersgcRIrrajAEMeta || {},
    alias: PartnersgcRIrrajAEMeta?.alias || [],
    redirect: PartnersgcRIrrajAEMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Partners.vue").then(m => m.default || m)
  },
  {
    name: Responsibility4S6Lkdd8ruMeta?.name ?? "AboutUs-Responsibility",
    path: Responsibility4S6Lkdd8ruMeta?.path ?? "/AboutUs/Responsibility",
    url: "/AboutUs/Responsibility",
    meta: Responsibility4S6Lkdd8ruMeta || {},
    alias: Responsibility4S6Lkdd8ruMeta?.alias || [],
    redirect: Responsibility4S6Lkdd8ruMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/AboutUs/Responsibility.vue").then(m => m.default || m)
  },
  {
    name: indexa8btVTAOETMeta?.name ?? "OSS",
    path: indexa8btVTAOETMeta?.path ?? "/OSS",
    url: "/OSS",
    meta: indexa8btVTAOETMeta || {},
    alias: indexa8btVTAOETMeta?.alias || [],
    redirect: indexa8btVTAOETMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/OSS/index.vue").then(m => m.default || m)
  },
  {
    name: AtelierNTsgN231zAMeta?.name ?? "Products-Atelier",
    path: AtelierNTsgN231zAMeta?.path ?? "/Products/Atelier",
    url: "/Products/Atelier",
    meta: AtelierNTsgN231zAMeta || {},
    alias: AtelierNTsgN231zAMeta?.alias || [],
    redirect: AtelierNTsgN231zAMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Atelier.vue").then(m => m.default || m)
  },
  {
    name: AtelierSimulatoriACD6h907GMeta?.name ?? "Products-AtelierSimulator",
    path: AtelierSimulatoriACD6h907GMeta?.path ?? "/Products/AtelierSimulator",
    url: "/Products/AtelierSimulator",
    meta: AtelierSimulatoriACD6h907GMeta || {},
    alias: AtelierSimulatoriACD6h907GMeta?.alias || [],
    redirect: AtelierSimulatoriACD6h907GMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/AtelierSimulator.vue").then(m => m.default || m)
  },
  {
    name: CloudIGVKW1hFTzMeta?.name ?? "Products-Cloud",
    path: CloudIGVKW1hFTzMeta?.path ?? "/Products/Cloud",
    url: "/Products/Cloud",
    meta: CloudIGVKW1hFTzMeta || {},
    alias: CloudIGVKW1hFTzMeta?.alias || [],
    redirect: CloudIGVKW1hFTzMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Cloud.vue").then(m => m.default || m)
  },
  {
    name: FabricRxbAg5numFMeta?.name ?? "Products-Fabric",
    path: FabricRxbAg5numFMeta?.path ?? "/Products/Fabric",
    url: "/Products/Fabric",
    meta: FabricRxbAg5numFMeta || {},
    alias: FabricRxbAg5numFMeta?.alias || [],
    redirect: FabricRxbAg5numFMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Fabric.vue").then(m => m.default || m)
  },
  {
    name: MarketRoXPGIwlkJMeta?.name ?? "Products-Market",
    path: MarketRoXPGIwlkJMeta?.path ?? "/Products/Market",
    url: "/Products/Market",
    meta: MarketRoXPGIwlkJMeta || {},
    alias: MarketRoXPGIwlkJMeta?.alias || [],
    redirect: MarketRoXPGIwlkJMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Market.vue").then(m => m.default || m)
  },
  {
    name: MixMatchvmHIob0nSfMeta?.name ?? "Products-MixMatch",
    path: MixMatchvmHIob0nSfMeta?.path ?? "/Products/MixMatch",
    url: "/Products/MixMatch",
    meta: MixMatchvmHIob0nSfMeta || {},
    alias: MixMatchvmHIob0nSfMeta?.alias || [],
    redirect: MixMatchvmHIob0nSfMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/MixMatch.vue").then(m => m.default || m)
  },
  {
    name: One43AR6F8MWFMeta?.name ?? "Products-One",
    path: One43AR6F8MWFMeta?.path ?? "/Products/One",
    url: "/Products/One",
    meta: One43AR6F8MWFMeta || {},
    alias: One43AR6F8MWFMeta?.alias || [],
    redirect: One43AR6F8MWFMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/One.vue").then(m => m.default || m)
  },
  {
    name: SimulatorcI9LJL02EGMeta?.name ?? "Products-Simulator",
    path: SimulatorcI9LJL02EGMeta?.path ?? "/Products/Simulator",
    url: "/Products/Simulator",
    meta: SimulatorcI9LJL02EGMeta || {},
    alias: SimulatorcI9LJL02EGMeta?.alias || [],
    redirect: SimulatorcI9LJL02EGMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Simulator.vue").then(m => m.default || m)
  },
  {
    name: StudiomnN8p2KUpnMeta?.name ?? "Products-Studio",
    path: StudiomnN8p2KUpnMeta?.path ?? "/Products/Studio",
    url: "/Products/Studio",
    meta: StudiomnN8p2KUpnMeta || {},
    alias: StudiomnN8p2KUpnMeta?.alias || [],
    redirect: StudiomnN8p2KUpnMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Products/Studio.vue").then(m => m.default || m)
  },
  {
    name: CasecwnHhPWJeMMeta?.name ?? "Resource-Case",
    path: CasecwnHhPWJeMMeta?.path ?? "/Resource/Case",
    url: "/Resource/Case",
    meta: CasecwnHhPWJeMMeta || {},
    alias: CasecwnHhPWJeMMeta?.alias || [],
    redirect: CasecwnHhPWJeMMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Case.vue").then(m => m.default || m)
  },
  {
    name: LearningmkAmzKI1wcMeta?.name ?? "Resource-Learning",
    path: LearningmkAmzKI1wcMeta?.path ?? "/Resource/Learning",
    url: "/Resource/Learning",
    meta: LearningmkAmzKI1wcMeta || {},
    alias: LearningmkAmzKI1wcMeta?.alias || [],
    redirect: LearningmkAmzKI1wcMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Learning.vue").then(m => m.default || m)
  },
  {
    name: NewsVIqsg74XsHMeta?.name ?? "Resource-News",
    path: NewsVIqsg74XsHMeta?.path ?? "/Resource/News",
    url: "/Resource/News",
    meta: NewsVIqsg74XsHMeta || {},
    alias: NewsVIqsg74XsHMeta?.alias || [],
    redirect: NewsVIqsg74XsHMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/News.vue").then(m => m.default || m)
  },
  {
    name: OlympicmMb37kFmLAMeta?.name ?? "Resource-Olympic",
    path: OlympicmMb37kFmLAMeta?.path ?? "/Resource/Olympic",
    url: "/Resource/Olympic",
    meta: OlympicmMb37kFmLAMeta || {},
    alias: OlympicmMb37kFmLAMeta?.alias || [],
    redirect: OlympicmMb37kFmLAMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Resource/Olympic.vue").then(m => m.default || m)
  },
  {
    name: AcademyJBpVVl7WXaMeta?.name ?? "Solutions-Academy",
    path: AcademyJBpVVl7WXaMeta?.path ?? "/Solutions/Academy",
    url: "/Solutions/Academy",
    meta: AcademyJBpVVl7WXaMeta || {},
    alias: AcademyJBpVVl7WXaMeta?.alias || [],
    redirect: AcademyJBpVVl7WXaMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Academy.vue").then(m => m.default || m)
  },
  {
    name: AuxiliaryngAu4oRrLoMeta?.name ?? "Solutions-Auxiliary",
    path: AuxiliaryngAu4oRrLoMeta?.path ?? "/Solutions/Auxiliary",
    url: "/Solutions/Auxiliary",
    meta: AuxiliaryngAu4oRrLoMeta || {},
    alias: AuxiliaryngAu4oRrLoMeta?.alias || [],
    redirect: AuxiliaryngAu4oRrLoMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Auxiliary.vue").then(m => m.default || m)
  },
  {
    name: Brandl5FZfdMMeAMeta?.name ?? "Solutions-Brand",
    path: Brandl5FZfdMMeAMeta?.path ?? "/Solutions/Brand",
    url: "/Solutions/Brand",
    meta: Brandl5FZfdMMeAMeta || {},
    alias: Brandl5FZfdMMeAMeta?.alias || [],
    redirect: Brandl5FZfdMMeAMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Brand.vue").then(m => m.default || m)
  },
  {
    name: FabricPZq7BQgAmVMeta?.name ?? "Solutions-Fabric",
    path: FabricPZq7BQgAmVMeta?.path ?? "/Solutions/Fabric",
    url: "/Solutions/Fabric",
    meta: FabricPZq7BQgAmVMeta || {},
    alias: FabricPZq7BQgAmVMeta?.alias || [],
    redirect: FabricPZq7BQgAmVMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Fabric.vue").then(m => m.default || m)
  },
  {
    name: ManufacturerBrN7WY6hmfMeta?.name ?? "Solutions-Manufacturer",
    path: ManufacturerBrN7WY6hmfMeta?.path ?? "/Solutions/Manufacturer",
    url: "/Solutions/Manufacturer",
    meta: ManufacturerBrN7WY6hmfMeta || {},
    alias: ManufacturerBrN7WY6hmfMeta?.alias || [],
    redirect: ManufacturerBrN7WY6hmfMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/Manufacturer.vue").then(m => m.default || m)
  },
  {
    name: VirtualFashionbr4FIh9QJ5Meta?.name ?? "Solutions-VirtualFashion",
    path: VirtualFashionbr4FIh9QJ5Meta?.path ?? "/Solutions/VirtualFashion",
    url: "/Solutions/VirtualFashion",
    meta: VirtualFashionbr4FIh9QJ5Meta || {},
    alias: VirtualFashionbr4FIh9QJ5Meta?.alias || [],
    redirect: VirtualFashionbr4FIh9QJ5Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Solutions/VirtualFashion.vue").then(m => m.default || m)
  },
  {
    name: ConsultationServiceSO46vlL0qJMeta?.name ?? "Support-ConsultationService",
    path: ConsultationServiceSO46vlL0qJMeta?.path ?? "/Support/ConsultationService",
    url: "/Support/ConsultationService",
    meta: ConsultationServiceSO46vlL0qJMeta || {},
    alias: ConsultationServiceSO46vlL0qJMeta?.alias || [],
    redirect: ConsultationServiceSO46vlL0qJMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/ConsultationService.vue").then(m => m.default || m)
  },
  {
    name: ContentServicestwSxS6WgDhMeta?.name ?? "Support-ContentServices",
    path: ContentServicestwSxS6WgDhMeta?.path ?? "/Support/ContentServices",
    url: "/Support/ContentServices",
    meta: ContentServicestwSxS6WgDhMeta || {},
    alias: ContentServicestwSxS6WgDhMeta?.alias || [],
    redirect: ContentServicestwSxS6WgDhMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/ContentServices.vue").then(m => m.default || m)
  },
  {
    name: CustomerSuccessKQQyrm4DmcMeta?.name ?? "Support-CustomerSuccess",
    path: CustomerSuccessKQQyrm4DmcMeta?.path ?? "/Support/CustomerSuccess",
    url: "/Support/CustomerSuccess",
    meta: CustomerSuccessKQQyrm4DmcMeta || {},
    alias: CustomerSuccessKQQyrm4DmcMeta?.alias || [],
    redirect: CustomerSuccessKQQyrm4DmcMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/CustomerSuccess.vue").then(m => m.default || m)
  },
  {
    name: Help9x5kVx3m3kMeta?.name ?? "Support-Help",
    path: Help9x5kVx3m3kMeta?.path ?? "/Support/Help",
    url: "/Support/Help",
    meta: Help9x5kVx3m3kMeta || {},
    alias: Help9x5kVx3m3kMeta?.alias || [],
    redirect: Help9x5kVx3m3kMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Support/Help.vue").then(m => m.default || m)
  },
  {
    name: indexDbdEeEC4GxMeta?.name ?? "Thanks",
    path: indexDbdEeEC4GxMeta?.path ?? "/Thanks",
    url: "/Thanks",
    meta: indexDbdEeEC4GxMeta || {},
    alias: indexDbdEeEC4GxMeta?.alias || [],
    redirect: indexDbdEeEC4GxMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/Thanks/index.vue").then(m => m.default || m)
  },
  {
    name: indexr7RfYBSCz2Meta?.name ?? "index",
    path: indexr7RfYBSCz2Meta?.path ?? "/",
    url: "/",
    meta: indexr7RfYBSCz2Meta || {},
    alias: indexr7RfYBSCz2Meta?.alias || [],
    redirect: indexr7RfYBSCz2Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpUVLqcdsGIMeta?.name ?? "page1",
    path: indexpUVLqcdsGIMeta?.path ?? "/page1",
    url: "/page1",
    meta: indexpUVLqcdsGIMeta || {},
    alias: indexpUVLqcdsGIMeta?.alias || [],
    redirect: indexpUVLqcdsGIMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/pages/page1/index.vue").then(m => m.default || m)
  }
]