interface ISeoConfigItem {
    title?: string;
    keywords?: string;
    description?: string;
}
interface ISeoConfig {
    [propName: string]: ISeoConfigItem;
}
// export type seoRouteName = 'index' | 'Products-Fabric' | 'Products-Studio' | 'Products-Source' | 'AboutUs-Introduction';
export type seoRouteName =
    | 'index'
    | 'Products-Studio'
    | 'Products-Fabric'
    | 'Products-One'
    | 'Solutions-Brand'
    | 'Solutions-Manufacturer'
    | 'Solutions-Fabric'
    | 'Solutions-Academy'
    | 'AboutUs-Introduction'
    | 'AboutUs-Responsibility'
    | 'AboutUs-Fellowship'
    | 'Support-ContentServices'
    | 'Support-CustomerSuccess'
    | 'Support-ConsultationService'
    | 'Resource-Partners';

export const seoConfig: ISeoConfig = {
    index: {
        title: 'Revolutioner of Fashion & Sustainability – Style3D',
        keywords: 'style3d, 3d fashion design software, fashion and sustainability',
        description:
            'Style3D revolutionizes fashion & sustainability. Our 3D fashion design software provides anyone in this industry with unmatched efficiency and ability.',
    },
    'Products-Studio': {
        title: 'Meet the Future of Fashion with Cloth Simulation - Style3D Studio',
        keywords: 'style3d studio, 3d fashion design software, cloth simulation',
        description:
            'Find out more about the advanced 3D fashion design software powered by state-of-the-art cloth simulation for realistic 3D prototyping and unmatched efficiency.',
    },
    'Products-Fabric': {
        title: 'Define Fashion on Digital Fabric Simulation Software - Style3D Fabric',
        keywords: 'style3d fabric, digital fabric,fabric simulation',
        description:
            'Style3D Fabric, the future of digital fabrics, empowers material innovation through true-to-life fabric simulation, editing and visualization capabilities.',
    },
    'Products-One': {
        title: 'Flagship of digital Asset Management for Fashion - Style3D One',
        keywords: '3d asset management, DAM for fashion,circular fashion ecosystem',
        description:
            'Realize the sustainable revolution through advanced DAM for fashion, facilitating the sharing and collaboration of 3D assets across the circular fashion ecosystem.',
    },
    'Solutions-Brand': {
        title: 'Ultra Fast 3D Sampling Software for Digital Fashion Brand - Style3D',
        keywords: 'digital fashion brand,digital prototype,3d sampling',
        description:
            'Style3D empowers the entire fashion process chain with digital fashion design & 3d prototyping solutions from planning, development to approval and marketing.',
    },
    'Solutions-Manufacturer': {
        title: 'Empower Digital Transformation with 3D Fashion Software - Style3D',
        keywords: 'digital fashion market,b2b fashion platform,3d fashion software',
        description:
            "Style3D's digital solutions streamline processes from asset management to collaboration & marketing for manufacturers through our advancing 3D fashion software.",
    },
    'Solutions-Fabric': {
        title: '3D Digital Fabric Modeling Platform - Style3D',
        keywords: 'digital fabric platform,fabric modeling,digital textile',
        description:
            "Style3D's digital fabric platform bonds fabric mills with brands through digital textile inventory management, digital showrooms and collaborative fabric modeling.",
    },
    'Solutions-Academy': {
        title: 'Bring Collaborative Fashion to 3D Fashion Design Courses - Style3D',
        keywords: 'digital fashion design course,3d fashion design course,collaborative fashion',
        description:
            "Convert your course into a 3d fashion design course that interweaves Style3D's simulation software into classrooms through collaborative fashion design.",
    },
    'AboutUs-Introduction': {
        title: 'Pathfinder in Digital Fashion Design - Style3D',
        keywords: 'style3d,digital fashion design,digital fashion',
        description:
            'Style3D, the professor of digital fashion, provides cutting-edge solutions supporting digital fashion industry. Click to establish partnership with us!',
    },
    'AboutUs-Responsibility': {
        title: 'Leader in the Revolution of Fashion & Sustainability - Style3D',
        keywords: 'sustainable fashion design,fashion and sustainability,fashion industry sustainability',
        description:
            'Style3D is weaving responsibility into sustainable fashion design by empowering designers to be digitally rooted in renewable resources.',
    },
    'AboutUs-Fellowship': {
        title: 'Fashion Fellowship for the Future of  Fashion Sustainability - Style3D',
        keywords: 'future of digital fashion,Fashion Fellowship,future of fashion sustainability',
        description:
            'Style3D invest in the future of fashion sustainability, we provide fellowship for students majoring in Fashion. Together, we empower the future of digital fashion.',
    },
    'Support-ContentServices': {
        title: '3D Clothing Asset Service, More than Digital Clothes Design - Style3D',
        keywords: 'digital clothes design,3d cloth modeling software,3d clothing assets',
        description:
            'Style3D helps to showcase & manage your digital clothes design through our advanced 3D cloth modeling software & 3D clothing assets management service.',
    },
    'Support-ConsultationService': {
        title: '3D Clothing Asset Service, More than Digital Clothes Design - Style3D',
        keywords: 'digital clothes design,3d cloth modeling software,3d clothing assets',
        description:
            'Style3D helps to showcase & manage your digital clothes design through our advanced 3D cloth modeling software & 3D clothing assets management service.',
    },
    'Support-CustomerSuccess': {
        title: 'Embrace Fashion Industry 4.0 & Fashion Digitalization - Style3D',
        keywords: 'fashion digitalization,fashion industry 4.0,digital transformation in fashion industry',
        description:
            "Usher into Fashion Industry 4.0 through fashion digitalization that empowers you to lead the industry with digitally skilled teams primed to pioneer fashion's future.",
    },
    'Resource-Partners': {
        title: 'Cultivate & Lead Sustainable Development in Fashion - Style3D',
        keywords: 'sustainable development in fashion,digital fashion design,future of sustainable fashion',
        description:
            'Style3D sow the future of sustainable fashion by collaborating with pioneers reimagining the systems and skills needed to shape sustainable developments in fashion.',
    },
};
