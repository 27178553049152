export const useCleanCache = () => {
    try {
        // 清除serviceWorker缓存，防止一直访问到旧官网，但也需要首次ctrl+shift+r强刷
        if ('serviceWorker' in window.navigator) {
            console.log('== Exec clean Cache');
            window.navigator.serviceWorker?.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    if (/linctex\.com/.test(registration.scope) && registration?.active?.state === 'activated') {
                        registration.unregister();
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
};
