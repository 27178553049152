export interface Route {
    key: string;
    name: string;
    t?: string;
    route?: string;
    routeName?: string;
    params?: any;
    children?: Route[];
}


export default defineNuxtPlugin(() => {

    // 默认layout
    const DEFAULT_LAYOUT = 'default';

    // 登录页
    const LOGIN_ROOT = 'login';

    // 首页
    const INDEX_ROOT = '';
    
    return {
        provide: {
            DEFAULT_LAYOUT,
            LOGIN_ROOT,
            INDEX_ROOT
        },
    };
});
