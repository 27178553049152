import { ref, onBeforeMount, Ref, reactive } from 'vue';

// onMounted
export function matchMobile() {
    return window.matchMedia('(max-width: 750px)').matches;
}

export function matchHighResolutionScreen() {
    return window.matchMedia('(min-width: 1440px)').matches;
}

export function getIsMobileRef(): Ref<boolean> {
    const isMobile = ref(false);

    onBeforeMount(() => {
        isMobile.value = matchMobile();
    });

    return isMobile;
}

export function getIsHighResolutionScreenRef(): Ref<boolean> {
    const isHighResolutionScreen = ref(false);

    onBeforeMount(() => {
        isHighResolutionScreen.value = matchHighResolutionScreen();
    });

    return isHighResolutionScreen;
}

export function getScreenMatcherRef() {
    const screenMatcher = reactive({
        isMobile: false,
        isHighResolutionScreen: false,
    });

    onBeforeMount(() => {
        screenMatcher.isMobile = matchMobile();
        screenMatcher.isHighResolutionScreen = matchHighResolutionScreen();
    });

    return screenMatcher;
}
