<script setup>
    import { watch, ref } from 'vue';
    // message css需要手动加载，此处引入 https://www.antdv.com/docs/vue/getting-started-cn#Import-on-Demand
    import 'ant-design-vue/es/message/style/css';
    import 'cropperjs/dist/cropper.css';
    import { debounce } from 'lodash-es';
    import LazyLoad from 'vanilla-lazyload';
    import { useWindowStore } from './store/windowStore';
    import { lazyloaderUpdate } from './composables/useLazyload';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import i18n, { setDefaultLang } from '@/locales';
    import { initGA } from '@/composables/useGA';
    import { initFloatingButton } from '@/components/Button/Floating/index.ts';
    import { requestCookiePermission, checkCookie } from '~~/composables/useCookie.ts';

    // 请求Cookie许可以及Google Analytics
    onMounted(async () => {
        if (!checkCookie()) {
            // console.log('Cookies permission not allowed');
            const permission = await requestCookiePermission();
            if (permission) {
                // console.log('Cookie permission granted');
                // 初始化Google Analytics
                initGA(localeLanguage.value);
            }
        } else {
            initGA(localeLanguage.value);
        }
    });
    // 浏览器环境使用
    if (process.client) {
        // Only initialize it one time for the entire application
        if (!document.lazyLoadInstance) {
            document.lazyLoadInstance = new LazyLoad({
                elements_selector: '.lazy',
                class_loading: 'lazy-loading', // 更改默认加载动画，防止加载时的抖动问题 https://github.com/verlok/vanilla-lazyload#options
            });
        }
    }

    const store = useWindowStore();
    const { $DEFAULT_LAYOUT } = useNuxtApp();
    useFonts();
    const route = useRoute();
    const router = useRouter();
    const layout = ref($DEFAULT_LAYOUT);
    const { t } = i18n.global;

    // set default
    const localeLanguage = useLocaleLanguage();
    setDefaultLang(localeLanguage.value);
    console.log('🏷 current locale language:', localeLanguage.value);

    // 监听路由，选择layout模板，重置seo
    watch(
        () => route.path,
        (path) => {
            if (process.client) lazyloaderUpdate();
            const protocolPageReg = /protocol/i;
            if (protocolPageReg.test(path)) layout.value = 'protocol';
            else if (
                path === '/' ||
                path === '/solutions/brand' ||
                path === '/solutions/manufacturer' ||
                path === '/solutions/fabric' ||
                path === '/thanks' ||
                path === '/products/cloud'
            )
                layout.value = 'nofooter';
            else layout.value = $DEFAULT_LAYOUT;
            useSeo();
        },
        { immediate: true },
    );

    // 在body上添加lang属性，https://juejin.cn/post/7003120831966478350#heading-24
    onBeforeMount(() => {
        // 更改语言状态
        watch(
            () => localeLanguage.value,
            () => {
                document.body.lang = localeLanguage.value;
                store.updateIsCN();
            },
            { immediate: true },
        );

        // 更新是否是移动端状态
        window.addEventListener('resize', () => {
            store.updatedIsMobile();
        });
    });

    const screenWidthState = reactive({
        pre: 0,
    });

    onMounted(() => {
        console.log('app onMounted');
        initFloatingButton();
        useCleanCache();
        setVariableVH();
        // 非移动端，监听窗口大小更新事件，实现页面刷新
        screenWidthState.pre = window.innerWidth;
        window.addEventListener('resize', () => {
            const currentWidth = window.innerWidth;
            if (
                (currentWidth < 750 && screenWidthState.pre > 750) ||
                (currentWidth > 750 && screenWidthState.pre < 750)
            ) {
                debounce(location.reload(), 1000);
                screenWidthState.pre = currentWidth;
            }
        });
    });
</script>

<template>
    <NuxtLayout :name="layout">
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang="less">
    @import './assets/styles/index.less';
</style>
