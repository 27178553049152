import { ifCNLocale } from './useLocale';

// 打开政策文件，根据语言环境打开不同的版本
export const openPolicyFile = (file: 'privacy' | 'cookie' | 'service') => {
    const isChinese = ifCNLocale();

    const privacyFilePath = isChinese
        ? '/file/隐私政策-Style3D-20231207.pdf'
        : '/file/Privacy Policy Style3D_20231207.pdf';
    const cookieFilePath = isChinese
        ? '/file/Cookie政策-Style3D-20231207.pdf'
        : '/file/Cookie Policy Style3D_20231207.pdf';
    const serviceFilePath = isChinese
        ? '/file/Service服务条款_Style3D_20240611.pdf'
        : '/file/Term of Service_Style3D_20240611.pdf';
    switch (file) {
        case 'privacy':
            window.open(privacyFilePath, '_blank');
            break;
        case 'cookie':
            window.open(cookieFilePath, '_blank');
            break;
        case 'service':
            window.open(serviceFilePath, '_blank');
            break;
    }
};
