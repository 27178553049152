<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M20.9997 3.21851H2.99972C2.77239 3.21851 2.55439 3.3088 2.39364 3.46954C2.2329 3.63029 2.14258 3.84832 2.14258 4.07565V16.3414C2.14258 16.5687 2.2329 16.7867 2.39364 16.9474C2.55439 17.1082 2.77239 17.1985 2.99972 17.1985H9.17974L11.2797 20.4085C11.3576 20.5279 11.4641 20.6259 11.5894 20.6938C11.7148 20.7617 11.855 20.7973 11.9976 20.7973C12.1401 20.7973 12.2805 20.7617 12.4058 20.6938C12.5311 20.6259 12.6376 20.5279 12.7155 20.4085L14.8198 17.1985H20.9997C21.227 17.1985 21.4451 17.1082 21.6059 16.9474C21.7666 16.7867 21.8569 16.5687 21.8569 16.3414V4.08423C21.858 3.97095 21.8367 3.85855 21.7941 3.75357C21.7516 3.64858 21.6886 3.55308 21.6089 3.47258C21.5292 3.39207 21.4343 3.32817 21.3297 3.28455C21.2252 3.24094 21.113 3.2185 20.9997 3.21851ZM20.1426 15.4756H14.3569C14.2142 15.4749 14.0737 15.5099 13.948 15.5772C13.8222 15.6446 13.7153 15.7422 13.6369 15.8614L11.9997 18.3642L10.3583 15.8614C10.2803 15.7429 10.174 15.6456 10.0491 15.5783C9.92421 15.5109 9.78448 15.4757 9.64258 15.4756H3.85686V4.93279H20.1426V15.4756Z"
            fill="white"
        />
        <path
            d="M16.0714 11.3572C16.6632 11.3572 17.1429 10.8775 17.1429 10.2858C17.1429 9.69405 16.6632 9.21436 16.0714 9.21436C15.4797 9.21436 15 9.69405 15 10.2858C15 10.8775 15.4797 11.3572 16.0714 11.3572Z"
            fill="white"
        />
        <path
            d="M12.0001 11.3572C12.5919 11.3572 13.0716 10.8775 13.0716 10.2858C13.0716 9.69405 12.5919 9.21436 12.0001 9.21436C11.4084 9.21436 10.9287 9.69405 10.9287 10.2858C10.9287 10.8775 11.4084 11.3572 12.0001 11.3572Z"
            fill="white"
        />
        <path
            d="M7.92885 11.3572C8.52058 11.3572 9.00028 10.8775 9.00028 10.2858C9.00028 9.69405 8.52058 9.21436 7.92885 9.21436C7.33712 9.21436 6.85742 9.69405 6.85742 10.2858C6.85742 10.8775 7.33712 11.3572 7.92885 11.3572Z"
            fill="white"
        />
    </svg>
</template>
