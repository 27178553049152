import Store from 'store';
import { getQueryObject } from '@tools/utils';
// 获取当前默认语言
export const useLocaleLanguage = () => useState<string>('locale', () => getDefaultLocale());

export const getDefaultLocale = (defaultLocale = 'en') => {
    const locale = ref('');
    // 系统语言
    const localeSystem = ref('');
    // 本地缓存语言
    const localeStore = ref(getCorrectLocaleName(unref(useCookie('lang')) || Store.get('lang') || ''));

    let url = '';
    if (process.server) {
        const reqLocale = useRequestHeaders()['accept-language']?.split(',')[0];
        if (reqLocale) {
            localeSystem.value = getCorrectLocaleName(reqLocale);
        }
        url = useRequestEvent()?.node.req.url || '';
    } else if (process.client) {
        const navLang = navigator.language;
        if (navLang) {
            localeSystem.value = getCorrectLocaleName(navLang);
        }
        url = window.location.href;
    }
    // url 指定语言
    const localeQuery = getCorrectLocaleName(getQueryObject(url).lang);

    // 有效的 url 指定语言时
    if (hasLocale(localeQuery)) locale.value = localeQuery;
    // locale 为空 , 有效的本地缓存语言
    if (!locale.value && localeStore.value && hasLocale(localeStore.value)) locale.value = localeStore.value;
    // locale 为空 , 有效的系统语言
    if (!locale.value && localeSystem.value && hasLocale(localeSystem.value)) locale.value = localeSystem.value;
    // default defaultLocale
    locale.value = locale.value || defaultLocale;

    return unref(locale);
};

export const getCorrectLocaleName = (locale: string) => {
    let val = null;
    if (/^zh/.test(locale)) val = 'zh-CN';
    if (/^en/.test(locale)) val = 'en';
    return val || locale;
};

// 对应后端数据配置的key ['en', 'zh']
export const getCorrectLocaleKey = () =>
    useState<string>('localeKey', () => {
        const locale = useLocaleLanguage();
        let key = null;
        if (/^zh/.test(locale.value)) key = 'zh';
        if (/^en/.test(locale.value)) key = 'en';
        return key || locale.value;
    });

export const useLocales = () => {
    const locales = ref([
        { locale: 'zh-CN', label: '简体中文', icon: 'lsicon-zhongwen' },
        { locale: 'en', label: 'English', icon: 'lsicon-yingwen' },
    ]);
    return unref(locales);
};

export const ifCNLocale = () => {
    const localeLanguage = useLocaleLanguage();
    return localeLanguage.value === 'zh-CN';
};

export const hasLocale = (locale: string) => {
    const locales = useLocales();
    return locales.some((i) => i.locale === locale);
};
