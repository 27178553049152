const HarmonyOSBold =
    '/fonts/HarmonyOS_Sans_SC_Bold.ttf';

const HarmonyOSMedium =
    '/fonts/HarmonyOS_Sans_SC_Medium.ttf';
const HarmonyOSRegular =
    '/fonts/HarmonyOS_Sans_SC_Regular.ttf';

const HarmonyOSLight =
    '/fonts/HarmonyOS_Sans_SC_Light.ttf';

const MontserratBold =
    '/fonts/Montserrat-Bold.ttf';

const MontserratMedium =
    '/fonts/Montserrat-Medium.ttf';

const MontserratRegular =
    '/fonts/Montserrat-Regular.ttf';

const NeuePowerW05 =
    '/fonts/NeuePower-Bold.ttf';

const BoutiqueBitmap9x9 =
    '/fonts/BoutiqueBitmap9x9_1.5.ttf';

const BoutiqueBitmap9x9Bold =
    '/fonts/BoutiqueBitmap9x9_Bold_1.5.ttf';

const loadFonts = async (fontFamily: string, fontUrl: string, descriptors?: Record<string, any>) => {
    try {
        const font = new FontFace(fontFamily, `url(${fontUrl})`, descriptors || {});
        await font.load();
        document.fonts.add(font);
    } catch (error) {}
};

export const useFonts = () => {
    const fontList = [
        {
            fontUrl: BoutiqueBitmap9x9,
            fontFamily: 'BoutiqueBitmap9x9 1.5',
            label: 'BoutiqueBitmap9x9',
            descriptors: {
                weight: 400,
            },
        },
        {
            fontUrl: BoutiqueBitmap9x9Bold,
            fontFamily: 'BoutiqueBitmap9x9 1.5',
            label: 'BoutiqueBitmap9x9Bold',
            descriptors: {
                weight: 700,
            },
        },
        {
            fontUrl: MontserratBold,
            fontFamily: 'Montserrat',
            label: 'MontserratBold',
            descriptors: {
                weight: 700,
            },
        },
        {
            fontUrl: MontserratMedium,
            fontFamily: 'Montserrat',
            label: 'MontserratMedium',
            descriptors: {
                weight: 500,
            },
        },
        {
            fontUrl: MontserratRegular,
            fontFamily: 'Montserrat',
            label: 'MontserratRegular',
            descriptors: {
                weight: 400,
            },
        },
        {
            fontUrl: NeuePowerW05,
            fontFamily: 'NeuePowerW05-Bold',
            label: 'NeuePowerW05-Bold',
        },
        {
            fontUrl: HarmonyOSBold,
            fontFamily: 'HarmonyOS Sans SC',
            label: '鸿蒙Bold',
            descriptors: {
                weight: 700,
            },
        },
        {
            fontUrl: HarmonyOSMedium,
            fontFamily: 'HarmonyOS Sans SC',
            label: '鸿蒙Medium',
            descriptors: {
                weight: 500,
            },
        },
        {
            fontUrl: HarmonyOSRegular,
            fontFamily: 'HarmonyOS Sans SC',
            label: '鸿蒙Regular',
            descriptors: {
                weight: 400,
            },
        },
        {
            fontUrl: HarmonyOSLight,
            fontFamily: 'HarmonyOS Sans SC',
            label: '鸿蒙Light',
            descriptors: {
                weight: 300,
            },
        },
    ];
    // 全部加载
    for (const i of fontList) {
        loadFonts(i.fontFamily, i.fontUrl, i.descriptors || {});
    }
};
