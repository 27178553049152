import { seoConfig, seoRouteName } from '~~/configs/seoConfig';
interface IHeadConfig {
    title?: string;
    meta: Array<{ name: string; content: string }>;
}

export const useSeo = () => {
    const route = useRoute();

    let originConfig = seoConfig[(route.name as seoRouteName) || 'index'];
    if (!originConfig) originConfig = seoConfig.index;
    if (!originConfig) return;

    const headConfig: IHeadConfig = { title: 'Style3D', meta: [] };
    if (originConfig.title) headConfig.title = originConfig.title;
    if (originConfig.keywords) headConfig.meta.push({ name: 'keywords', content: originConfig.keywords });
    if (originConfig.description) headConfig.meta.push({ name: 'description', content: originConfig.description });

    useHead(headConfig);
};
